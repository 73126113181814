<template>
  <div class="store-widget-v2__one-column-list">
    <one-column-item v-for="(item, index) in list" :key="index" :site="site" :editing="editing" :good="item" :model="model" @click="handleShowDetail" @submit="onSubmit" />
  </div>
</template>

<script>
import OneColumnItem from './item.vue'
export default {
  name: 'StoreWidgetV2OneColumnList',
  components: {
    OneColumnItem
  },
  props: {
    editing: {
      type: Boolean,
      default: false
    },
    site: {
      type: Object,
      default() {
        return {}
      }
    },
    model: {
      type: Object,
      default() {
        return {}
      }
    },
    list: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {}
  },
  methods: {
    handleShowDetail(goods) {
      this.$emit('show-detail', goods)
    },
    onSubmit(goods) {
      this.$emit('submit', goods)
    }
  }
}
</script>

<style lang="less">
.store-widget-v2__one-column-list {
  padding: 14px 14px 0 14px;
}
</style>
