<template>
  <widget-content
    :bg="model.background"
    :mobile-bg="model.mobileBackground"
    :class="[isMobile && 'is-mobile']"
  >
    <div class="flex widget-footer">
      <div
        :class="isMobile ? 'footer_content_mobile relative z-10': 'footer_content_desk relative z-10'"
        class="w-full widget-footer__list"
        :style="getStyle"
      >
        <div
          v-if="(hasLogo && isMobile) || showLangSelect"
          class="footer_lang_select mb-4 border-b border-b-fillColor-3 flex justify-between"
        >
          <bg-style v-if="hasLogo && isMobile" :bg="model.logoBackground" class="w-[120px] h-[40px]"/>
          <lang-select-component
            v-if="showLangSelect"
            :device="device"
            :editing="editing"
            source="footer"
          />
        </div>
        <div v-for="(item, index) in model.children" :key="index" class="footer-widget__item">
          <div v-if="item.name === 'FooterTextWidgetModel'" class="mb-4 pb-6 border-b border-b-fillColor-3">
            <rich-text v-model="item.text" :disabled="!editing" :editing="editing" />
          </div>
          <div v-if="item.name === 'FooterMediaWidgetModel'" class="pb-6 mb-4 border-b border-b-fillColor-3">
            <div class="footer-media text-14 font-bold">{{ item.title }}</div>
            <div class="flex items-center mt-2">
              <bg-style
                v-for="(tag, mediaIndex) in item.navs"
                :key="mediaIndex"
                :bg="tag.logo"
                class="item w-[28px] h-[28px] mr-8 cursor-pointer"
                @click.native="handleClickToNav(tag)"
              />
            </div>
          </div>
          <div v-if="item.name === 'FooterQuickLinkWidgetModel'" :class="{ 'grid-cols-2': isMobile, 'grid-cols-5': !isMobile }" class="mb-4 grid border-b gap-4 pb-4 border-b-fillColor-3">
            <div v-for="(nav, navIndex) in item.list" :key="'nav' + navIndex" class="quick-link__column">
              <div class="title font-bold mb-2 text-14">{{ nav.title }}</div>
              <div v-for="(navItem, tagIndex) in nav.navs" :key="'tag' + tagIndex" :title="navItem.label" class="nav-item cursor-pointer mb-2" @click="handleClickToNav(navItem)">{{ navItem.label }}</div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isMobile" class="widget-footer__logo py-9 pl-5 flex-shrink-0">
        <bg-style :bg="model.logoBackground" class="w-[120px] h-[40px]"/>
      </div>
    </div>
  </widget-content>
</template>

<script>
import {mapState} from 'vuex'
import {FooterWidgetModel} from '../../model/index'
import LangSelectComponent from '../../components/langSelect'
import WidgetContent from '~/site/components/widgetContent'
import {DefaultImage} from '~/enums/defaultImageEnum'
import {navTo} from '~/utils'
import {DeviceEnum} from "~/enums/deviceEnum";
import RichText from "~/components/richText/index.vue";

export default {
  name: 'FooterWidget',
  components: {
    RichText,
    LangSelectComponent,
    WidgetContent
  },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
    site: {
      type: Object,
      default() {
        return {}
      },
    },
    model: {
      type: Object,
      default() {
        return new FooterWidgetModel()
      },
    },
    device: {
      type: String,
      default: 'desktop',
    },
  },
  data() {
    return {
      DefaultImage,
    }
  },
  computed: {
    getStyle() {
      return {
        color: this.model.footerFontColorShow ? this.model.footerFontColor : 'unset'
      }
    },
    DeviceEnum() {
      return DeviceEnum
    },
    ...mapState({
      realDevice: (state) => state.device.device,
    }),
    // 是否移动端
    isMobile() {
      const device = this.editing ? this.device : this.realDevice
      return device === 'mobile'
    },
    hasLogo() {
      return this.model.logoBackground?.backgroundImage
    },
    showLangSelect() {
      return (
        this.site &&
        this.site.langSetting &&
        this.site.langSetting.langPosition === 'bottom'
      )
    },
  },
  methods: {
    handleClickToNav(item) {
      if (this.editing) return
      navTo(this, item)
    },
  },
}
</script>

<style lang="less" scoped>
.footer-widget {
  &__item {
    &:last-of-type {
      & > div {
        margin-bottom: 0 !important;
        border-bottom: none !important;
      }
    }
    .nav-item {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}
.footer_content_desk {
  padding: 36px 0;

  .footer_logo {
    width: 100%;
    height: 40px;
    margin-bottom: 16px;

    .logo_cover_block {
      position: absolute !important;
      height: 40px !important;
    }
  }

  .footer_lang_select {
    padding-bottom: 24px;

    .lang-select-component {
      padding: 7px;
      border: 1px solid white;
      border-radius: 2px;
    }
  }

  .footer_text {
    width: 660px;

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  .footer_text_image {
    display: flex;
    flex-wrap: wrap;

    .item_text_image {
      width: 20%;
      display: flex;
      justify-items: center;

      .footer-image {
        width: 100%;
        height: 60px;
      }

      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }

  .split_line_block {
    margin: 36px 0;
  }

  .split_line_none {
    margin: 18px 0;
  }

  .footer_link {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
}

.footer_content_mobile {
  padding: 24px 16px;
  margin-top: 28px;

  .footer_lang_select {
    padding-bottom: 24px;

    .lang-select-component {
      padding: 4px;
      border: 1px solid white;
      border-radius: 2px;
    }
  }

  .quick-link__column {
    .item {
      margin-bottom: 12px;

      //&:last-of-type {
      //  margin-bottom: 26px;
      //}
    }
  }

  .quick-link__logo {
    width: 100%;
    padding-top: 24px;
    border-top: 1px solid @fill-color-3;
  }

  .footer_text {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  .footer_text_image {
    .item_text_image {
      width: 100%;

      .footer-image {
        width: 100%;
        height: 60px;
      }

      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }

  .footer_link {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}

.footer_logo_text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer_split_line {
  width: 100%;
  height: 1px;
  position: relative;
  z-index: 99;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
