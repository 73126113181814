<template>
  <div class="activity-widget-edit-wrap">
    <!-- 类型 -->
<!--    <menu-item :label="$t('menu.activityType')">-->
<!--      <im-select v-model="model.activityType" class="w-full"  :options="typeOptions" size="small" />-->
<!--    </menu-item>-->
    <!-- 背景图片 -->
    <menu-item :label="$t('head.backImg')">
      <menu-fill :target="model.background" target-key="background" />
    </menu-item>
    <menu-item label="PC端背景图">
      <menu-background-image class="mt-2" :target="model.background" target-key="background" />
    </menu-item>
    <menu-item label="移动端背景图">
      <menu-background-image class="mt-2" :target="model.mobileBackground" target-key="mobileBackground" />
    </menu-item>
    <!-- 布局 -->
    <menu-item :border-bottom="true" class="menu-item-switch pt-4 pb-3" :label="$t('menu.layout')">
      <menu-switch v-model="model.titleVisible" label="标题" target-key="titleVisible" />
      <menu-switch v-model="model.subtitleVisible" label="描述" target-key="subtitleVisible" />
    </menu-item>
    <menu-item label="文字颜色">
      <menu-fill :target="model.textColor" target-key="textColor" />
    </menu-item>
    <menu-item label="按钮文字颜色">
      <menu-fill :target="model.buttonTextColor" target-key="buttonTextColor" />
    </menu-item>
    <menu-item label="奖品卡片背景">
      <menu-fill :target="model.cardBackground" target-key="cardBackground" />
      <menu-background-image class="mt-2" :target="model.cardBackground" target-key="cardBackground" />
    </menu-item>
    <menu-item label="奖品LOGO背景">
      <menu-fill :target="model.logoBackground" target-key="logoBackground" />
      <menu-background-image class="mt-2" :target="model.logoBackground" target-key="logoBackground" />
    </menu-item>
    <menu-item label="领取按钮">
      <menu-fill :target="model.getBackground" target-key="getBackground" />
      <menu-background-image class="mt-2" :target="model.getBackground" target-key="getBackground" />
    </menu-item>
    <menu-item label="不可领取按钮">
      <menu-fill :target="model.disabledBackground" target-key="disabledBackground" />
      <menu-background-image class="mt-2" :target="model.disabledBackground" target-key="disabledBackground" />
    </menu-item>
    <menu-item label="进度条背景">
      <menu-fill :target="model.progressBackground" target-key="progressBackground" />
      <menu-background-image class="mt-2" :target="model.progressBackground" target-key="progressBackground" />
    </menu-item>
    <menu-item label="进度条填充">
      <menu-fill :target="model.innerBackground" target-key="innerBackground" />
      <menu-background-image class="mt-2" :target="model.innerBackground" target-key="innerBackground" />
    </menu-item>
    <remove-btn class="mt-4" />
  </div>
</template>

<script>
import cloneDeep from "lodash.clonedeep";
import { ActivityWidgetModel } from '../../model/index'
import MenuItem from "~/components/menu/menuItem";
import MenuSwitch from "~/components/menu/menuSwitch";
import ImSelect from "~/components/common/ImSelect";
import MenuFill from "~/components/menu/menuFill";
import MenuBackgroundImage from "~/components/menu/menuBackgroundImage";
import {insertStep} from "~/utils/event";

export default {
  name: "ActivityWidgetEdit",
  components: {
    MenuItem,
    MenuSwitch,
    MenuFill,
    ImSelect,
    MenuBackgroundImage
  },
  props: {
    model: {
      type: Object,
      default() {
        return new ActivityWidgetModel()
      }
    }
  },
  data() {
    return {
      menuList: [],
      typeOptions: [
        // { label: '买X送Y', value: '1' },
        { label: '累充', value: '2' },
      ]
    }
  },

  methods: {
    switchChange(value, item) {
      const layout = cloneDeep(this.model.layout)
      insertStep(this, ['layout'], layout)
      item.value = value
    }
  }
}
</script>

<style lang='less' scoped>
</style>
<style lang="less">
.activity-rich-text {
  position: relative;
  margin-bottom: 16px;
  .ql-editor.ql-blank::before {
    color: rgba(255, 255, 255, 0.3);
    font-style: normal;
  }
  .ql-bubble {
    .ql-tooltip {
      position: absolute;
      top: 38px !important;
      left: 0 !important;
      background: #151724 !important;
      border: 0.5px solid rgba(255, 255, 255, 0.3);
      z-index: 2021;
    }
  }
  .rich-text {
    border-radius: 2px;
    &:hover {
      box-shadow: none;
    }
  }
}
</style>
