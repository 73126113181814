<template>
  <div class="store-widget-v2__pc-list relative">
    <div class="content-area grid grid-cols-4 gap-9">
      <pc-list-item v-for="(item, index) in list" :key="index" :site="site" :editing="editing" :good="item" :model="model" @click="handleShowDetail" @submit="onSubmit" />
    </div>
  </div>
</template>

<script>
import PcListItem from './item.vue'

export default {
  name: 'StoreWidgetV2PcList',
  components: {PcListItem},
  props: {
    editing: {
      type: Boolean,
      default: false
    },
    site: {
      type: Object,
      default() {
        return {}
      }
    },
    model: {
      type: Object,
      default() {
        return {}
      }
    },
    list: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
    }
  },
  methods: {
    handleShowDetail(goods) {
      this.$emit('show-detail', goods)
    },
    onSubmit(goods) {
      this.$emit('submit', goods)
    }
  }
}
</script>

<style lang="less">
.store-widget-v2__pc-list {
  padding: 24px 0 40px 0;
  .content-area {
    max-width: 1280px;
    margin: 0 auto;
  }
}
</style>
