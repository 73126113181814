// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".store-widget-v2__pc-list{padding:24px 0 40px}.store-widget-v2__pc-list .content-area{margin:0 auto;max-width:1280px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
