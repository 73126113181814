<template>
  <div class="single-goods__edit">
    <menu-item label="背景设置">
      <menu-fill :target="model.background" target-key="background" />
    </menu-item>
    <menu-item label="PC端背景图">
      <menu-background-image :target="model.background" target-key="background" />
    </menu-item>
    <menu-item label="移动端背景图">
      <menu-background-image :target="model.mobileBackground" target-key="mobileBackground" />
    </menu-item>
    <menu-item label="布局">
      <menu-switch v-model="model.titleVisible" label="标题" target-key="titleVisible" />
      <menu-switch v-model="model.subtitleVisible" label="描述" target-key="subtitleVisible" />
    </menu-item>
    <menu-item label="PC端每行数量">
      <im-select v-model="model.layout" class="w-full" :options="layoutOptions" size="small" />
    </menu-item>
    <menu-item label="商品背景圆角">
      <menu-slider v-model="model.goodsRadius" target-key="goodsRadius" />
    </menu-item>
    <menu-button :help-text="$t('menu.copyBlock')" class="mt-4" icon="add" type="ghost-copy" @click.native.stop="handleClone">{{ $t('menu.createCopy') }}</menu-button>
    <remove-btn class="mt-4" />
  </div>
</template>

<script>
import MenuItem from "~/components/menu/menuItem.vue"
import MenuFill from "~/components/menu/menuFill.vue"
import RemoveBtn from "~/components/common/RemoveBtn.vue";
import MenuBackgroundImage from "~/components/menu/menuBackgroundImage.vue";
import MenuSwitch from "~/components/menu/menuSwitch.vue";
import MenuSlider from "~/components/menu/menuSlider.vue";
import MenuButton from "~/components/menu/menuButton.vue";
import ImSelect from "~/components/common/ImSelect.vue";

export default {
  name: "SingleGoodsWidgetEdit",
  components: {ImSelect, MenuButton, MenuSlider, MenuSwitch, MenuBackgroundImage, RemoveBtn, MenuFill, MenuItem},
  props: {
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      layoutOptions: [
        {
          label: '1个',
          value: '1'
        },
        {
          label: '2个',
          value: '2'
        }
      ]
    }
  },
  methods: {
    handleClone() {
      this.$emit('clone', this.model.clone())
    }
  }
}
</script>

<style scoped lang="less">

</style>
