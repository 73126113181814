<template>
  <bg-style class="text-widget" :bg="model.background" :mobile-bg="model.mobileBackground">
    <div class="text-widget__content relative">
      <rich-text v-model="model.text" :visible="model.titleVisible" :stroke.sync="model.titleStroke" :editing="editing" />
    </div>
  </bg-style>
</template>

<script>
import RichText from "~/components/richText/index.vue";

export default {
  name: "TextWidget",
  components: {
    RichText
  },
  props: {
    editing: {
      type: Boolean,
      default: false
    },
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
    }
  },
  created() {
    //
  }
}
</script>

<style scoped lang="less">
.text-widget {
  padding: 30px 10px;
  &__content {
    margin: 0 auto;
    max-width: 1200px;
  }
}
</style>
