<template>
  <div class="relative" :style="{...customStyle, ...getStyle}">
    <div v-if="showBackgroundImage" v-show="isShowBackgroundImage" class="absolute top-0 left-0 bottom-0 right-0 pointer-events-none" :style="bgStyle" />
    <div v-if="showMobileBackgroundImage" v-show="isShowMobileBackgroundImage" class="absolute top-0 left-0 bottom-0 right-0 pointer-events-none" :style="mobileStyle" />
    <div v-if="bg.backgroundColor" v-show="isShowColor" class="absolute top-0 left-0 bottom-0 right-0 pointer-events-none color-bg" :style="bgColorStyle" />
    <slot/>
  </div>
</template>

<script>
import {computeBackgroundStyle, isShowColor, isShowBackgroundImage} from '~/site/model/helper'

export default {
  name: 'CommonBackgroundStyle',
  props: {
    mobileBg: {
      type: Object,
      default() {
        return {}
      }
    },
    bg: {
      type: Object,
      default() {
        return {}
      }
    },
    customStyle: {
      type: Object,
      default() {
        return {}
      }
    },
    radius: {
      type: Number,
      default: 0
    }
  },
  computed: {
    getStyle() {
      const style = {}
      if (this.radius) {
        style.borderRadius = this.radius + 'px'
        style.overflow = 'hidden'
      }
      return style
    },
    isShowColor() {
      return isShowColor(this.bg)
    },
    isShowBackgroundImage() {
      return isShowBackgroundImage(this.bg)
    },
    isShowMobileBackgroundImage() {
      return isShowBackgroundImage(this.mobileBg)
    },
    mobileStyle() {
      return computeBackgroundStyle(this.mobileBg, {})
    },
    bgStyle() {
      return computeBackgroundStyle(this.bg, {})
    },
    showBackgroundImage() {
      return (this.bg.backgroundImage && this.$store.getters.isDesktop) || (!this.mobileBg.backgroundImage || !this.mobileBg.backgroundImageShow)
    },
    showMobileBackgroundImage() {
      return (this.mobileBg.backgroundImage && this.$store.getters.isMobile) || (!this.bg.backgroundImage || !this.bg.backgroundImageShow)
    },
    bgColorStyle() {
      const style = {
        background: this.bg.backgroundColor || this.bg.background
      }
      if (this.radius) {
        style.borderRadius = this.radius + 'px'
        style.overflow = 'hidden'
      }
      return style
    }
  }
}
</script>

<style scoped>

</style>
