<template>
  <bg-style class="single-goods" :class="{ 'is-mobile': $store.getters.isMobile }" :mobile-bg="model.mobileBackground" :bg="model.background">
    <div class="single-goods__content relative">
      <rich-text v-if="model.titleVisible" v-model="model.title" :class="[$store.getters.isMobile ? 'text-[22px]' : 'text-[40px]']" :disabled="!editing" :editing="editing" :stroke.sync="model.titleStroke" class="text-[20px] w-full relative title" theme="snow"/>
      <rich-text v-if="model.subtitleVisible" v-model="model.subtitle" :class="[$store.getters.isMobile ? 'text-[12px]' : 'text-[18px]']" :disabled="!editing" :editing="editing" :stroke.sync="model.subTitleStroke" class="mt-3 text-14 relative" theme="snow"/>
      <div class="single-goods__list relative" :class="{ 'justify-center': model.layout === '1', 'grid-cols-2': model.layout === '2' && !$store.getters.isMobile }">
        <good-item v-for="(item, index) in goodsList" :key="`${index}` + item.id" :good="item" :model="model" :editing="editing" :is-mobile="$store.getters.isMobile" :store-lang="storeLang" :good-model="model.children[index]" @item-click="handleItemClick" @click.native.stop="handleOpenMenu(model.children[index], index)"/>
      </div>
    </div>
    <goods-detail v-if="showDetail" ref="$detail" :model="currentGoodsModel" :site="site" :is-mobile="$store.getters.isMobile" :good="currentGoods" :store-lang="storeLang" @buy="onSubmitOrder" @close="showDetail = false" />
    <DeskPayModal
      v-if="deskPay.visible"
      :desk-pay="deskPay"
      :store-lang="storeLang"
      :lang-id-data="$store.state.locale.langIdData"
      @close="closePay"
    />
  </bg-style>
</template>

<script>
import cloneDeep from 'lodash.clonedeep'
import {Message} from 'element-ui'
import GoodItem from './goodsItem.vue'
import RichText from '~/components/richText/index.vue'
import tools, {checkIgnoreMessage, checkUser, generateOrderParams, generatePayUrl} from "~/utils"
import GoodsDetail from '~/site/widgets/storeWidget/detail.vue'
import {selectWidgetMenu} from '~/site/core'
import DeskPayModal from '~/components/pay/deskPayModal.vue'
import Bus from '~/site/model/bus'
import {DefaultImage} from '~/enums/defaultImageEnum'
export default {
  name: 'SingleGoodsWidget',
  components: {DeskPayModal, GoodsDetail, RichText, GoodItem},
  props: {
    editing: {
      type: Boolean,
      default: false
    },
    site: {
      type: Object,
      default() {
        return {}
      }
    },
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      deskPay: {
        visible: false,
        isGoPay: false,
        payUrl: '',
        orderCode: ''
      },
      orderLoading: false,
      storeLang: '',
      showDetail: false,
      currentGoodsModel: {},
      currentGoods: {}
    }
  },
  computed: {
    goodsList() {
      const ids = this.model.children.map(item => item.goodsId)
      const goodsList = cloneDeep(this.$store.state.goods.goodsList)
      const result = []
      const empty = () => {
        return this.editing ? {
          names: {
            lang_id: 'Name'
          },
          icon: DefaultImage.store,
          corner: DefaultImage.Corner,
          descriptions: {
            lang_id: 'Description'
          }
        } : null
      }
      ids.forEach((id) => {
        const current = goodsList.find(item => item.id === id) || empty()
        current && result.push(current)
      })
      return result
    },
    awaitGoods() {
      return this.$store.state.site.awaitGoods
    }
  },
  created() {
    this.init()
  },
  mounted() {
    Bus.$on('reloadGood', (type) => {
      const isLogin = type === 'login'
      if (isLogin) {
        this.checkBuy()
      }
    })
  },
  methods: {
    checkBuy() {
      const isAim = this.awaitGoods && (this.awaitGoods.modelId === this.model.id)
      if (isAim) this.createOrder(this.awaitGoods.data)
    },
    async init() {
      this.storeLang = tools.getStoreLang(this)
      await this.$store.dispatch('goods/getGroupList', this)
      await this.$store.dispatch('goods/getGoodsByGroups', this)
    },
     handleOpenMenu(goodModel, index) {
      if (this.editing) selectWidgetMenu(this, this.model, goodModel, index)
    },
    async onSubmitOrder() {
      if (this.orderLoading) return
      await checkUser(this, () => {
        this.showDetail = false
        this.$store.commit('site/SET_AWAIT_GOODS', { modelId: this.model.id, data: cloneDeep(this.currentGoods) })
      })
      await this.createOrder()
    },
    async createOrder(goods) {
      this.showDetail = false
      this.orderLoading = true
      const [err, res] = await this.$utils.to(generateOrderParams(this, goods || this.currentGoods))
      this.orderLoading = false
      if (!err) {
        this.$store.commit('site/SET_AWAIT_GOODS', null)
        generatePayUrl(res, this)
      } else {
        !checkIgnoreMessage(err) && Message.error(err)
      }
    },
    closePay() {
      this.deskPay = {
        visible: false,
        isGoPay: false,
        payUrl: '',
        orderCode: ''
      }
    },
    handleItemClick(goods, goodsModel) {
      if (this.editing) return
      const model = {
        buyBackground: goodsModel.buttonBackground,
        goodsTitleColor: goodsModel.nameBackground.backgroundColor,
        goodsTitleColorShow: goodsModel.nameBackground.backgroundColorShow,
        goodsPriceColor: goodsModel.priceBackground.backgroundColor,
        goodsPriceColorShow: goodsModel.priceBackground.backgroundColorShow,
        ...goodsModel
      }
      this.currentGoods = goods
      this.currentGoodsModel = model
      this.showDetail = true
      this.$nextTick(() => {
        this.$refs.$detail.init()
      })
    }
  }
}
</script>

<style lang="less">
.single-goods {
  padding: 60px 0 40px 0;
  &__content {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
  }
  &__list {
    display: grid;
    gap: 48px;
    margin-top: 40px;
    .item {
      width: 560px;
      .thumb {
        height: 220px;
      }
      .goods-info {
        padding: 24px;
        height: 100px;
        grid-gap: 8px;
        grid-template-areas: "content originalprice button";
        grid-template-columns: 1fr;
        .name {
          font-weight: 400;
          font-size: 24px;
        }
        .desc {
          font-weight: 400;
          font-size: 16px;
          line-height: 1.5;
        }
      }
    }
  }
  &.is-mobile {
    padding: 40px 0 40px 0;
    .single-goods__list {
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      padding: 0 32px;
      gap: 16px 0;
    }
  }
}
</style>
