<template>
  <bg-style class="store-list relative" :bg="model.background" :mobile-bg="model.mobileBackground" :class="{ 'is-mobile': device === DeviceEnum.MOBILE }">
    <div class="store-list__content" :style="getStyle">
      <rich-text v-model="model.title" :visible="model.titleVisible" :stroke.sync="model.titleStroke" :editing="editing" :class="[$store.getters.isMobile ? 'text-[22px]' : 'text-[40px]']" class="w-full relative title" theme="snow" :exclude="['font-size']" :disabled="!editing" />
      <rich-text v-model="model.subtitle" :visible="model.subtitleVisible" :stroke.sync="model.subTitleStroke" :editing="editing" class="mt-3 relative" :class="[$store.getters.isDesktop ? 'text-[18px]' : 'text-[12px]']" theme="snow" :exclude="['font-size']" :disabled="!editing" />
      <div class="type-area mt-10">
        <div v-for="(item, index) in classList" :key="index" class="item whitespace-nowrap text-ellipsis" @click="handleChangeType(index)">
          {{ item.label }}
          <div v-if="current === index" class="line" />
        </div>
      </div>
      <div v-imloading="loading" class="goods-area relative mt-8">
        <div v-if="!loading && !goodsList.length" class="flex w-full items-center justify-center">
          <el-empty class="relative" description=" " />
        </div>
        <div v-for="(item, index) in goodsList" :key="item.id" class="item">
          <bg-style class="flex items-center relative justify-between h-full cursor-pointer item-content overflow-hidden" :bg="(currentGoodsId === item.id || moveIndex === index) ? model.btnBackground : model.goodsBackground" @mouseout.native="ouMouseOut" @mousemove.native="onMouseIn(index)" @click.native="handleSelect(item)">
            <div class="left-info relative items-center flex">
              <img v-if="model.picVisible" class="goods-pic" :src="item.icon" alt="">
              <div>
                <div class="title" :style="getTextColor(model.nameBackground)" :title="item.names.name">{{ item.names.name }}</div>
                <div v-if="item.descriptions.desc" class="describe mt-2" :title="item.descriptions.desc">{{ item.descriptions.desc }}</div>
              </div>
            </div>
            <div class="flex-shrink-0" :style="getTextColor(model.priceBackground)">
              <div v-if="item.stopTimer.distance > 0" class="relative text-center mb-1 stop-time">
                <i class="el-icon-time" />
                <span>{{ item.stopTimer.hours }}:{{ item.stopTimer.minutes }}:{{ item.stopTimer.seconds }}</span>
              </div>
              <bg-style :bg="model.priceBtnBackground" :radius="46" class="price-block">
                <div v-if="item.startTimer.distance > 0" class="relative start-time">
                  <span class="day">{{ item.startTimer.days }} <em class="not-italic">Day</em></span>
                  <span>{{ item.startTimer.hours }}:{{ item.startTimer.minutes }}:{{ item.startTimer.seconds }}</span>
                </div>
                <span v-else class="relative price">{{ item.priceInfo.priceTxt }}</span>
              </bg-style>
              <div v-if="item.limit_purchase_num" class="relative limit">
                Limit {{ item.has_purchase_num }}/{{ item.limit_purchase_num }}
              </div>
            </div>
          </bg-style>
        </div>
      </div>
      <div class="handle-area mt-8 rounded flex w-full items-center justify-between">
        <div class="left-price relative flex items-start justify-center flex-col">
          <div class="text-20">
            {{ $t('siteBuild.totalAmount') }}:<span class="ml-2 text-28">{{ currentGoods.priceInfo?.priceTxt }}</span>
          </div>
          <div class="text-14" :class="$store.getters.isMobile ? 'max-w-[200px]' : 'max-w-[600px]'">
            <span v-if="currentGoods?.names" :title="currentGoods?.names?.name">{{ currentGoods.names?.name }}：</span>
            <span :title="currentGoods.descriptions?.desc">{{ currentGoods.descriptions?.desc }}</span>
          </div>
        </div>
        <bg-style class="buy cursor-pointer px-5 flex-shrink-0 py-2 flex items-center justify-center" :radius="8" :bg="model.btnBackground" @click.native="handleSubmit">
          <div class="flex items-center">
            <i v-if="orderLoading" class="el-icon-loading mr-2" />
            <div class="relative text-20" :style="getTextColor(model.nameBackground)">{{ $t('siteBuild.shopCart.buy') }}</div>
          </div>
        </bg-style>
      </div>
    </div>
    <DeskPayModal
      v-if="deskPay.visible"
      :desk-pay="deskPay"
      :store-lang="storeLang"
      :lang-id-data="$store.state.locale.langIdData"
      @close="closePay"
    />
  </bg-style>
</template>

<script>
import { Message, Empty } from 'element-ui'
import cloneDeep from 'lodash.clonedeep'
import RichText from '~/components/richText/index.vue'
import tools, {
  checkIgnoreMessage,
  checkUser,
  formatZoneTime,
  generateOrderParams,
  generatePayUrl,
  getTextColor
} from '~/utils'
import {DeviceEnum} from '~/enums/deviceEnum'
import Bus from '~/site/model/bus'
import DeskPayModal from '~/components/pay/deskPayModal.vue'
import {DefaultLanguage} from '~/site/model'

export default {
  name: 'StoreListWidget',
  components: {
    DeskPayModal,
    RichText,
    [Empty.name]: Empty
  },
  props: {
    device: {
      type: String,
      default: DeviceEnum.MOBILE
    },
    editing: {
      type: Boolean,
      default: false
    },
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      current: 0,
      currentGoodsId: '',
      loading: false,
      goodsList: [],
      storeLang: DefaultLanguage,
      moveIndex: -1,
      deskPay: {
        visible: false,
        isGoPay: false,
        payUrl: '',
        orderCode: ''
      },
      orderLoading: false,
      list: []
    }
  },
  computed: {
    DeviceEnum() {
      return DeviceEnum
    },
    classList() {
      const result = []
      this.model.classList.forEach(target => {
        const current = this.$store.state.goods.groupList.find(item => item.value === target.id)
        current && result.push(current)
      })
      return result
    },
    currentGoods() {
      return this.goodsList.find(item => item.id === this.currentGoodsId) || {}
    },
    awaitGoods() {
      return this.$store.state.site.awaitGoods
    },
    getStyle() {
      return {
        '--desc-color': getTextColor(this.model.descBackground).color
      }
    }
  },
  async created() {
    this.storeLang = tools.getStoreLang(this)
    await this.$store.dispatch('goods/getGroupList', this)
    this.setDefaultClassify()
    await this.getGoodsList()
  },
  mounted() {
    Bus.$on('reloadGood', (type) => {
      const isLogin = type === 'login'
      if (isLogin) {
        this.checkBuy()
      }
    })
  },
  methods: {
    getTextColor,
    setDefaultClassify() {
      if (this.editing && this.model.classList.length === 0) {
        this.model.classList = this.$store.state.goods.groupList.map(item => {
          return {
            id: item.value
          }
        })
      }
    },
    checkBuy() {
      const isAim = this.awaitGoods && (this.awaitGoods.modelId === this.model.id)
      if (isAim) this.createOrder(this.awaitGoods.data)
    },
    closePay() {
      this.deskPay = {
        visible: false,
        isGoPay: false,
        payUrl: '',
        orderCode: ''
      }
    },
    handleChangeType(index) {
      if (this.current === index) return
      this.current = index
      this.currentGoodsId = ''
      this.goodsList = []
      this.getGoodsList()
    },
    async getGoodsList() {
      const params = {
        group_id: this.classList[this.current]?.value,
        pageSize: 100,
        pageNo: 1,
        project_id: this.$store.state.project.projectId
      }
      if (!params.group_id) return
      this.loading = true
      const [err, res] = await this.$utils.to(this.$api.good.getVirtualItems(params, { editing: this.editing }))
      this.loading = false
      if (!err) {
        this.goodsList = res.data.map(item => {
          const newItem = {...item}
          newItem.names = tools.getGoodInfo(item.names, {}, this.storeLang, this, this.$store.state.locale.langIdData)
          newItem.descriptions = tools.getGoodInfo({}, item.descriptions, this.storeLang, this, this.$store.state.locale.langIdData)
          newItem.priceInfo = tools.getPriceInfo(newItem.prices_setting, tools.getLangByip(this), this)
          newItem.startTimer = {
            distance: 0,
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0
          }
          newItem.stopTimer = {
            distance: 0,
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0
          }
          newItem.startTime = null
          newItem.stopTime = null
          if (newItem.start_time) {
            this.startCountdown(newItem)
          }
          if (newItem.stop_time) {
            this.stopCountdown(newItem)
          }
          return newItem
        })
      }
    },
    startTimeCompute(countdownTimestamp, now, item) {
      item.startTimer.distance = countdownTimestamp - now
      const days = Math.floor(item.startTimer.distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((item.startTimer.distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      const minutes = Math.floor((item.startTimer.distance % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = Math.floor((item.startTimer.distance % (1000 * 60)) / 1000)
      item.startTimer.days = days < 10 ? `0${days}` : days
      item.startTimer.hours = hours < 10 ? `0${hours}` : hours
      item.startTimer.minutes = minutes < 10 ? `0${minutes}` : minutes
      item.startTimer.seconds = seconds < 10 ? `0${seconds}` : seconds
      if (item.startTimer.distance <= 0) {
        clearInterval(item.startTime)
      }
    },
    stopCountdown(item) {
      const now = Date.now()
      const stop = formatZoneTime(item.stop_time * 1000).value
      if (now >= stop) {
        return null
      }
      item.stopTime = setInterval(() => {
        const now = Date.now();
        item.stopTimer.distance = stop - now;
        const days = Math.floor(item.stopTimer.distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((item.stopTimer.distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        const minutes = Math.floor((item.stopTimer.distance % (1000 * 60 * 60)) / (1000 * 60))
        const seconds = Math.floor((item.stopTimer.distance % (1000 * 60)) / 1000)
        item.stopTimer.days = days < 10 ? `0${days}` : days
        item.stopTimer.hours = hours < 10 ? `0${hours}` : hours
        item.stopTimer.minutes = minutes < 10 ? `0${minutes}` : minutes
        item.stopTimer.seconds = seconds < 10 ? `0${seconds}` : seconds
        if (item.stopTimer.distance <= 0) {
          clearInterval(item.stopTime)
        }
      }, 1000)
    },
    startCountdown(item) {
      const startTimestamp = Date.now()
      const countdownTimestamp = formatZoneTime(item.start_time * 1000).value
      if (countdownTimestamp <= startTimestamp) {
        item.startTimer.distance = 0
        return
      }
      this.startTimeCompute(countdownTimestamp, startTimestamp, item)
      item.startTime = setInterval(() => {
        const now = Date.now()
        this.startTimeCompute(countdownTimestamp, now, item)
      }, 1000);
    },
    handleSelect(item) {
      this.currentGoodsId = item.id
    },
    onMouseIn(index) {
      this.moveIndex = index
    },
    ouMouseOut() {
      this.moveIndex = -1
    },
    async createOrder(goods) {
      this.orderLoading = true
      const [err, res] = await this.$utils.to(generateOrderParams(this, goods || this.currentGoods))
      this.orderLoading = false
      if (!err) {
        this.$store.commit('site/SET_AWAIT_GOODS', null)
        generatePayUrl(res, this)
      } else {
        !checkIgnoreMessage(err) && Message.error(err)
      }
    },
    async handleSubmit() {
      if (!this.currentGoodsId || this.orderLoading) return
      await checkUser(this, () => {
        this.$store.commit('site/SET_AWAIT_GOODS', { modelId: this.model.id, data: cloneDeep(this.currentGoods) })
      })
      await this.createOrder()
    }
  }
}
</script>

<style lang="less">
.store-list {
  padding: 60px 0 40px 0;
  &__content {
    max-width: 1200px;
    margin: 0 auto;
    .type-area {
      display: flex;
      align-items: center;
      overflow-x: auto;
      .item {
        padding: 10px 30px;
        text-align: center;
        cursor: pointer;
        position: relative;
        flex-shrink: 0;
        font-size: 20px;
        border-bottom: 2px solid #dedede;
        .line {
          background-color: var(--button-background-color);
          height: 2px;
          width: 80px;
          position: absolute;
          bottom: -2px;
          left: 50%;
          transform: translate(-50%, 0);
          z-index: 10;
        }
      }
    }
    .goods-area {
      display: flex;
      flex-wrap: wrap;
      .no-data {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .item {
        width: 50%;
        padding: 8px;
        &-content {
          box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
          border-radius: 8px;
          border: 1px solid #dedede;
          padding: 16px;
          .left-info {
            width: 70%;
            .goods-pic {
              width: 100px;
              height: 100px;
              flex-shrink: 0;
              margin-right: 20px;
            }
            .title {
              font-size: 20px;
            }
            .describe {
              font-size: 16px;
              display: -webkit-box;
              overflow: hidden;
              white-space: normal;
              text-overflow: ellipsis;
              word-wrap: break-word;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              color: var(--desc-color);
            }
          }
          .stop-time {
            color: var(--desc-color);
          }
          .limit {
            width: max-content;
            margin: 5px auto;
            color: var(--desc-color);
          }
          .start-time {
            font-size: 18px;
          }
          .price-block {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px 15px;
            .price {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
  &.is-mobile {
    padding: 40px 15px 40px 15px;
    .type-area {
      .item {
        font-size: 14px;
      }
    }
    .goods-area {
      max-height: 480px;
      overflow-y: auto;
      .item {
        width: 100%;
        padding: 0;
        margin-bottom: 10px;
        &-content {
          padding: 10px;
        }
        .goods-pic {
          width: 80px;
          height: 80px;
          margin-right: 10px;
        }
        .title {
          font-size: 14px;
        }
        .describe {
          font-size: 12px;
        }
        .start-time {
          font-size: 12px;
        }
        .price-block {
          .price {
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>
