// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".store-widget-v2{padding:0 0 40px}.store-widget-v2__classify{padding:60px 0 20px}.store-widget-v2__classify .content-area{margin:0 auto;max-width:1280px}.store-widget-v2__classify .content-area .group-list{font-size:16px;overflow:auto}.store-widget-v2__classify .content-area .group-list::-webkit-scrollbar{display:none}.store-widget-v2__classify .content-area .group-list .item{border-radius:var(--goods-border-radius);color:var(--second-text-color);cursor:pointer;flex-shrink:0;padding:5px 12px}.store-widget-v2__classify .content-area .group-list .item.active{background-color:var(--button-bg-color);color:var(--button-text-color)}.store-widget-v2.is-mobile .store-widget-v2__classify{padding:40px 14px 20px}.store-widget-v2.is-mobile .store-widget-v2__classify .group-list{font-size:12px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
