<template>
  <el-badge :value="goodsNumber" :hidden="goodsNumber <= 0">
    <div class="shop-cart" @click.stop="handleClick">
      <i v-if="goodsNumber <= 0" class="el-icon-shopping-cart-2" />
      <i v-else class="el-icon-shopping-cart-full" />
    </div>
  </el-badge>
</template>

<script>
import shopCartMixins from "~/mixins/shopCartMixins";
export default {
  name: 'StoreWidgetV2ShopCart',
  mixins: [shopCartMixins],
  data() {
    return {
    }
  }
}
</script>

<style scoped lang="less">
.shop-cart {
  cursor: pointer;
}
</style>
