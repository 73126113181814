<template>
  <div class="store-widget-v2__row-column-list">
    <div class="flex overflow-x-auto gap-x-[14px] py-1 px-1 scroll-list">
      <row-column-item v-for="(item, index) in list" :key="index" :site="site" :editing="editing" :good="item" :model="model" @click="handleShowDetail" @submit="onSubmit" />
    </div>
  </div>
</template>

<script>
import RowColumnItem from './item.vue'
export default {
  name: 'StoreWidgetV2RowColumnList',
  components: {
    RowColumnItem
  },
  props: {
    editing: {
      type: Boolean,
      default: false
    },
    site: {
      type: Object,
      default() {
        return {}
      }
    },
    model: {
      type: Object,
      default() {
        return {}
      }
    },
    list: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {}
  },
  methods: {
    handleShowDetail(goods) {
      this.$emit('show-detail', goods)
    },
    onSubmit(goods) {
      this.$emit('submit', goods)
    }
  }
}
</script>

<style lang="less">
.store-widget-v2__row-column-list {
  padding: 14px 14px 0 14px;
  display: flex;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
