<template>
  <div class="goods-info" :style="storeVarStyle">
    <div v-if="stopCountdownTimer && !isShowCountdown" class="goods-info__timer text-18 flex items-baseline relative">
      <i class="el-icon-timer text-24" />
      <span class="ml-1">{{ stopCountdownDays }}d:{{ stopCountdownHours }}:{{ stopCountdownMinutes }}:{{ stopCountdownSeconds }}</span>
    </div>
    <div class="goods-info__name" :style="getColorStyle(model.goodsTitleColor, model.goodsTitleColorShow)">{{ goodName }}</div>
    <div class="goods-info__price">
      <span>{{ realPriceText }}</span>
      <span v-if="isShowOriginalPrice" class="origin-price">{{ originalPriceText }}</span>
    </div>
    <div v-if="hasGift" class="goods-info__gifts">
      <div class="text-14 title">{{ $t('siteBuild.gift') }}：</div>
      <div class="list mt-1 max-h-[220px] overflow-y-auto">
        <div v-for="(item, index) in good.gift_list" :key="index" class="item">
          <img :src="item.gift_goods_logo" alt="">
          <div class="flex items-center">
            <i class="el-icon-close" :style="getColorStyle(model.goodsTitleColor, model.goodsTitleColorShow)" />
            <div class="gift-item__num" :style="getColorStyle(model.goodsTitleColor, model.goodsTitleColorShow)">{{ item.num }}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="hasContents" class="goods-info__gifts">
      <div class="text-14 title">{{ $t('siteBuild.includes') }}：</div>
      <div class="list mt-1 max-h-[220px] overflow-y-auto">
        <div v-for="(item, index) in good.item_contents" :key="index" class="item">
          <div class="flex items-center">
            <img :src="item.icon" alt="">
            <span class="title">{{ getLangIdI18n({ lang_id: item.name }, $store.state.locale.langIdData) }}</span>
          </div>
          <div class="flex items-center">
            <i class="el-icon-close" :style="getColorStyle(model.goodsTitleColor, model.goodsTitleColorShow)" />
            <div class="gift-item__num" :style="getColorStyle(model.goodsTitleColor, model.goodsTitleColorShow)">{{ item.num }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="goods-info__describe">{{ goodDescription }}</div>
    <div class="goods-info__other">
      <div v-if="good.is_purchase_limit" class="good-limit relative px-1" :style="tagStyle">
        Limit {{ good.has_purchase_num }}/{{ good.limit_purchase_num }}
      </div>
      <div v-if="realPriceInfo.offText" class="good-limit relative px-1" :style="tagStyle">
        {{ realPriceInfo.offText }}
      </div>
    </div>
    <div class="goods-info__handle cursor-pointer" :class="{ 'disabled': isShowCountdown || isSaleOut }">
      <div class="top-line" />
      <shop-cart v-if="site.globalSetting.shopCartEnabled" class="w-[44px] h-[44px] mr-3 flex-shrink-0" :good="good" :disabled="editing" force-pc :model="site.globalSetting" />
      <bg-style class="buy" :bg="model.buyBackground" @click.native="handleBuy">
        <div v-if="isShowCountdown" class="countdown">
          <span class="day">{{ countdownDays }} <em>Day</em></span>
          <span>{{ countdownHours }}:{{ countdownMinutes }}:{{ countdownSeconds }}</span>
        </div>
        <span v-else class="relative" :style="getColorStyle(model.goodsPriceColor, model.goodsPriceColorShow)">{{ realPriceText }}</span>
      </bg-style>
    </div>
  </div>
</template>

<script>
import goodMixins from "~/site/widgets/storeWidget/oneGood/goodMixins";
import shopCart from "~/site/widgets/storeWidget/oneGood/shopCart.vue";

export default {
  name: "GoodsInfo",
  components: {shopCart},
  mixins: [
    goodMixins
  ],
  props: {
    site: {
      type: Object,
      default() {
        return {}
      }
    },
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
    }
  },
  methods: {
    init() {
      this.initGoods()
    },
    handleBuy() {
      this.$emit('buy')
    }
  }
}
</script>


<style scoped lang="less">
.goods-info {
  position: relative;
  &__name {
    font-size: 18px;
    font-weight: 600;
  }
  &__timer {
    margin-bottom: 6px;
    color: var(--title-color-50);
  }
  &__price {
    font-size: 30px;
    line-height: 46px;
    font-weight: 600;
    display: flex;
    color: var(--title-color-50);
    .origin-price {
      font-size: 16px;
      font-weight: normal;
      text-decoration: line-through;
      color: var(--title-color-30);
      margin-left: 8px;
    }
  }
  &__describe {
    color: var(--title-color-50);
    margin-top: 20px;
    word-break: break-word;
    font-size: 14px;
    line-height: 22px;
  }
  &__other {
    margin-top: 20px;
    display: flex;
    gap: 12px;
  }
  &__handle {
    display: flex;
    margin-top: 24px;
    &.disabled {
      opacity: 0.36;
      pointer-events: none;
    }
    .shop-cart {
      width: 44px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
    }
    .buy {
      line-height: 44px;
      width: 100%;
      text-align: center;
      .countdown {
        position: relative;
        font-size: 16px;
        z-index: 10;
        color: var(--price-opacity-color);
        .day {
          border-right: 1px solid var(--price-opacity-color);
          padding-right: 6px;
          margin-right: 6px;
          em {
            font-style: normal;
          }
        }
      }
    }
  }
  &__gifts {
    margin-top: 20px;
    .list {
      padding: 20px;
      background-color: var(--title-color-4);
      border-radius: 2px;
      position: relative;
      &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
      }
      .item {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        justify-content: space-between;
        font-size: 14px;
        img {
          width: 40px;
          height: 40px;
          margin-right: 12px;
        }
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
    .title {
      color: var(--title-color-50);
    }
  }
}
</style>
