<template>
  <el-empty v-if="disabled" :description="$t('siteBuild.noActivity')" class="relative"/>
  <div v-else :class="{ 'is-mobile': isMobile }" :style="getStyle" class="consecutive-body relative">
    <div class="w-full flex justify-between consecutive-body__header">
      <div class="flex items-center">
        <span>{{ $t('siteBuild.consecutiveWidget.subtitle') }}：{{ hasDay }} </span>
      </div>
      <span class="flex items-center time">{{ sTime }}</span>
    </div>
    <div ref="content" class="consecutive-body__content">
      <template v-if="controlVisible">
        <div :class="{ 'is-disabled': prevDisabled }" class="prev control-btn" @click="handlePrev">
          <i class="el-icon-arrow-left"/>
        </div>
        <div :class="{ 'is-disabled': nextDisabled }" class="next control-btn" @click="handleNext">
          <i class="el-icon-arrow-right"/>
        </div>
      </template>
      <div :style="scrollStyle" class="scroll-list">
        <bg-style :bg="model.progressBackground" class="progress">
          <bg-style :bg="model.innerBackground" :style="{ width: progressWidth }" class="inner relative h-full"/>
        </bg-style>
        <div v-for="(item, index) in list" :key="index" :ref="`item-${item.day_no}`" :class="{ 'is-active': item.active }" class="item">
          <div class="circle z-10 relative"/>
          <div class="number">{{ item.day_no }}</div>
          <bg-style :bg="model.cardBackground" class="w-full card mt-4" @click.native="handleItemClick(item)">
            <bg-style :bg="model.logoBackground" class="thumb relative">
              <img :src="item.thumb" alt="" class="relative"/>
            </bg-style>
            <div class="info-area relative">
              <div class="day mt-3 text-center">
                {{ $t('siteBuild.signWidget.day', {day: item.day_no}) }}
              </div>
              <div class="mx-auto w-full overflow-auto">
                <div class="gifts gap-2">
                  <div v-for="(gift, giftIndex) in item.gift_list" :key="giftIndex"
                       class="pack flex items-center flex-shrink-0">
                    <img :src="gift.gift_goods_logo" alt="" class="w-[40px] h-[40px]"/>
                    <i class="el-icon-close"/>
                    <span class="text-12">{{ gift.num }}</span>
                  </div>
                </div>
              </div>
              <bg-style v-if="item.get_status === 1" :bg="model.disabledBackground" class="handle mt-[14px]" @click.native.stop>
                <span class="relative">{{ $t('siteBuild.activity.haveGet') }}</span>
              </bg-style>
              <bg-style v-if="item.get_status === 2" :bg="model.getBackground" class="handle mt-[14px]" @click.native.stop="handlePick(item)">
                <span class="relative">{{ $t('siteBuild.activity.get') }}</span>
              </bg-style>
              <bg-style v-if="item.get_status === 3" :bg="model.disabledBackground" class="handle mt-[14px]" @click.native.stop>
                <span class="relative">{{ $t('siteBuild.activity.notQualified') }}</span>
              </bg-style>
            </div>
          </bg-style>
        </div>
      </div>
    </div>
    <info-detail ref="info" :data="currentRow" :is-mobile="isMobile"/>
  </div>
</template>

<script>
import isNil from "lodash.isnil";
import {Message, Empty} from "element-ui";
import moment from "moment/moment";
import {mapState} from "vuex";
import cloneDeep from "lodash.clonedeep";
import InfoDetail from './info.vue'
import {formatZoneTime, getTextColor} from "~/utils";
import Bus from "~/site/model/bus";

export default {
  name: 'ConsecutiveWidgetContent',
  components: {
    [Empty.name]: Empty,
    InfoDetail
  },
  props: {
    editing: {
      type: Boolean,
      default: false
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      scrollNumber: 0,
      list: [],
      loading: false,
      activityData: {},
      sTime: '00:00:00',
      disabled: false,
      hasDay: 0,
      maxDay: 0,
      currentRow: {},
      viewNumber: 0,
      progressWidth: 0
    }
  },
  computed: {
    getStyle() {
      return {
        '--text-color': getTextColor(this.model.textColor).color,
        '--button-text-color': getTextColor(this.model.buttonTextColor).color,
        '--circle-background': getTextColor(this.model.progressBackground).color,
        '--progress-inner-color': getTextColor(this.model.innerBackground).color
      }
    },
    controlVisible() {
      return this.list.length > this.viewNumber
    },
    scrollStyle() {
      const left = 172 * this.scrollNumber
      return {
        transform: `translateX(-${left}px)`
      }
    },
    maxScrollNumber() {
      return this.list.length - this.viewNumber
    },
    prevDisabled() {
      return this.scrollNumber === 0
    },
    nextDisabled() {
      return this.scrollNumber === this.maxScrollNumber
    },
    ...mapState({
      userInfo: (state) => state.user.siteUserInfo,
      projectId: (state) => state.project.projectId,
    })
  },
  watch: {
    isMobile() {
      const timer = setTimeout(() => {
        this.generateProgress()
        this.resetScroll()
        clearTimeout(timer)
      }, 500)
    }
  },
  mounted() {
    this.init()
    Bus.$on('reloadActivity', () => {
      this.resetScroll()
      this.init()
    })
  },
  destroyed() {
    Bus.$off('reloadActivity')
  },
  methods: {
    async init() {
      this.getViewNumber()
      await this.getActivityData()
      this.locationAvailableItem()
    },
    getViewNumber() {
      if (this.disabled) return
      this.viewNumber = Math.floor(this.$refs.content.offsetWidth / 172)
    },
    resetScroll() {
      this.scrollNumber = 0
      const $content = this.$refs.content
      $content && $content.scrollTo({
        left: 0,
        behavior: 'instant'
      })
    },
    handlePrev() {
      const number = this.scrollNumber - 1
      if (number >= 0) {
        this.scrollNumber -= 1
      }
    },
    handleNext() {
      const number = this.scrollNumber + 1
      if (number <= this.maxScrollNumber) {
        this.scrollNumber += 1
      }
    },
    handleItemClick(data) {
      if (this.editing) return
      this.currentRow = data
      this.$refs.info.init()
    },
    async handlePick(item) {
      if (this.editing) return
      if (isNil(this.userInfo?.id)) {
        this.$store.commit('user/SET_LOGINSHOW', !this.editing)
        this.$store.commit('user/SET_SITE_BIND_USER', true)
      } else {
        const params = {
          activity_id: this.activityData.biz_id,
          day_no: item.day_no
        }
        const [err] = await this.$utils.to(this.$api.activity.rechargePick(params))
        if (!err) {
          Message.success('Success')
          await this.getRechargeList()
        } else {
          Message.error(err)
        }
      }
    },
    locationAvailableItem() {
      const nextIndex = this.list.findIndex(item => item.get_status === 2)
      const noFound = nextIndex === -1
      const current = nextIndex + 1
      const isNoView = (current > this.viewNumber)
      if (!isNoView || noFound) return
      const gapNumber = current - this.viewNumber
      if (this.isMobile) {
        const offsetLeft = this.$refs[`item-${this.list[nextIndex].day_no}`][0].offsetLeft
        this.$refs.content.scrollTo({
          left: offsetLeft,
          behavior: 'instant'
        })
      } else {
        this.scrollNumber = gapNumber
      }
    },
    async getRechargeList() {
      if (this.editing) {
        return await this.generateProgress()
      } else if (!this.userInfo) return
      const params = {
        activity_id: this.activityData.biz_id
      }
      const [err, res] = await this.$utils.to(this.$api.activity.getRechargeList(params))
      if (!err) {
        this.hasDay = res.has_recharged_days
        this.maxDay = res.max_day
        this.list = res.recharge_list.map(item => {
          const newItem = {...item}
          newItem.thumb = item.gift_list[0].gift_goods_logo
          newItem.active = this.hasDay >= item.day_no
          return newItem
        })
        await this.generateProgress()
      } else {
        Message.error(err)
      }
    },
    async generateProgress() {
      await this.$nextTick()
      const {list, hasDay} = this
      const activeArr = []
      const pLeft = this.isMobile ? 0 : 12
      const halfWidth = this.isMobile ? 108 / 2 : 160 / 2
      const areaWidth = this.isMobile ? 98 : 150
      let nearItem
      for (let i = 0; i < list.length; i++) {
        const item = list[i]
        if (hasDay >= item.day_no) {
          activeArr.push(item)
        }
        if (item.day_no > hasDay) {
          nearItem = cloneDeep(item)
          break
        }
      }
      const isMax = list[list.length - 1]?.active
      const lastActive = activeArr[activeArr.length - 1]
      const isHit = hasDay === lastActive?.day_no
      if (isMax) {
        this.progressWidth = '100%'
      } else if (isHit) {
        const $lastActive = this.$refs[`item-${lastActive.day_no}`][0]
        this.progressWidth = ($lastActive.offsetLeft + halfWidth - pLeft) + 'px'
      } else if (nearItem && lastActive) {
        const $lastActive = this.$refs[`item-${lastActive.day_no}`][0]
        const gapNumber = nearItem.day_no - lastActive.day_no
        const averageWidth = areaWidth / gapNumber
        const repairWidth = averageWidth * (hasDay - lastActive.day_no)
        const lastOffset = $lastActive.offsetLeft + halfWidth - pLeft
        this.progressWidth = (lastOffset + repairWidth + 12) + 'px'
      } else {
        const percent = hasDay / list[0]?.day_no
        this.progressWidth = (halfWidth * percent)+ 'px'
      }
    },
    activityUnusual() {
      if (this.editing) {
        this.hasDay = 2
        this.list = cloneDeep(this.model.__mockData)
        this.generateProgress()
      } else {
        this.disabled = true
      }
    },
    clearData() {
      this.hasDay = 0
      this.maxDay = 0
    },
    async getActivityData() {
      if (this.loading) return
      this.clearData()
      this.loading = true
      const now_stem = Math.floor(moment().utc().valueOf() / 1000)
      const params = {
        now_time: now_stem,
        activity_type: 7,
        player_id: (this.userInfo && this.userInfo.uid || '').trim(),
        project_id: this.projectId || this.userInfo.project_id || ''
      }
      const [err, res] = await this.$utils.to(this.$api.activity.getActivityList(params, {editing: this.editing}))
      this.loading = false
      if (!err) {
        const data = (res.list || []).filter(item => item.status === 2)[0]
        if (!data) {
          this.activityUnusual()
          return
        }
        const rule = JSON.parse(data.activity_rule)
        this.activityData = data
        this.hasDay = this.editing ? rule.recharge_list?.[1]?.day_no : 0
        this.maxDay = rule.recharge_list[rule.recharge_list.length - 1]?.day_no || 0
        this.list = rule.recharge_list.map((item, index) => {
          const newItem = {...item}
          const status = (index + 1) > 3 ? 3 : (index + 1)
          newItem.get_status = this.editing ? status : 3
          newItem.active = this.hasDay >= item.day_no
          newItem.thumb = item.gift_list[0].gift_goods_logo
          return newItem
        })
        this.countTime()
        await this.getRechargeList()
      } else {
        Message.error(res)
      }
    },
    countTime() {
      const now = moment().valueOf()
      const end = formatZoneTime(this.activityData.stop_time * 1000).value
      const leftTime = end - now;
      let d = 0;
      let h = 0;
      let m = 0;
      let s = 0;
      if (leftTime >= 0) {
        d = Math.floor(leftTime / 1000 / 60 / 60 / 24);
        h = Math.floor(leftTime / 1000 / 60 / 60 % 24);
        m = Math.floor(leftTime / 1000 / 60 % 60);
        s = Math.floor(leftTime / 1000 % 60);
      }
      this.sTime = `${d > 0 ? `${d}d:` : ''}${h < 10 ? `0${h}` : h}:${m < 10 ? `0${m}` : m}:${s < 10 ? `0${s}` : s}`
      if (leftTime > 0) {
        setTimeout(this.countTime, 1000)
      } else {
        this.activityUnusual()
      }
    }
  }
}
</script>

<style lang="less">
.consecutive-body {
  &__header {
    color: var(--text-color);
    font-size: 20px;
  }

  &__content {
    width: 100%;
    margin-top: 24px;
    overflow-x: hidden;
    position: relative;

    .scroll-list {
      display: flex;
      gap: 12px;
      width: max-content;
      padding-left: 12px;
      transition: all 0.36s ease;
      position: relative;
      //margin: 0 auto;
      .progress {
        position: absolute !important;
        width: calc(100% - 12px);
        left: 12px;
        height: 4px;
        top: 8px;
      }

      .item {
        width: 160px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
        flex-shrink: 0;

        &:last-of-type {
          .progress {
            width: 100%;
          }
        }

        .circle {
          width: 20px;
          height: 20px;
          background-color: var(--circle-background);
          border-radius: 100%;
          margin-bottom: 12px;
          border: 4px solid transparent;
        }

        .number {
          font-size: 24px;
          color: var(--circle-background);
        }

        .card {
          box-shadow: 2px 0 5px 0 rgba(0, 0, 0, 0.08);
          cursor: pointer;

          &:hover {
            .thumb img {
              transform: scale(1.15);
            }
          }

          .thumb {
            width: 100%;
            height: 120px;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;

            img {
              max-height: 100%;
              transition: all 0.24s ease;
            }
          }

          .day {
            color: var(--text-color);
          }

          .info-area {
            width: 100%;
            padding: 0 16px 16px 16px;

            .gifts {
              display: flex;
              margin: 0 auto;
              padding-top: 10px;
              overflow: auto;
              max-width: 100%;
              white-space: nowrap;
              color: var(--text-color);
              flex-wrap: nowrap;
              .pack {
                &:first-of-type {
                  margin: 0 auto;
                }
              }
              &::-webkit-scrollbar {
                height: 0;
              }
            }

            .handle {
              line-height: 32px;
              width: 100%;
              color: var(--button-text-color);

              span {
                text-align: center;
                width: 100%;
                display: block;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              }
            }
          }
        }

        &.is-active {
          div.circle {
            background-color: white;
            border-color: var(--progress-inner-color);
          }
          .number {
            color: var(--text-color);
          }
        }
      }
    }

    .control-btn {
      position: absolute;
      width: 54px;
      height: 54px;
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 100%;
      top: 175px;
      z-index: 10;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      display: flex;
      transition: all 0.24s ease;
      opacity: 0;
      pointer-events: none;

      i {
        font-size: 24px;
        color: white;
        font-weight: bold;
      }

      &.prev {
        left: 12px;
      }

      &.next {
        right: 8px;
      }

      &.is-disabled {
        opacity: 0 !important;
        pointer-events: none;
        cursor: default;
      }

      &:hover {
        background-color: white;
        box-shadow: 2px 0 5px 0 rgba(0, 0, 0, 0.08);

        i {
          color: black;
        }
      }
    }

    &:hover {
      .control-btn {
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  &.is-mobile {
    .consecutive-body__header {
      font-size: 14px;
      .time {
        margin-right: 16px;
      }
    }

    .consecutive-body__content {
      overflow-x: auto;

      .control-btn {
        display: none !important;
      }

      .scroll-list {
        padding-left: 0;
        .progress {
          width: 100%;
          height: 3px;
          top: 5px;
          left: 0;
          //left: 5px;
        }
      }

      .item {
        width: 108px;

        .thumb {
          height: 81px;
        }

        .circle {
          height: 12px;
          width: 12px;
          border: 2px solid transparent;
          margin-bottom: 5px;
        }

        .number {
          font-size: 16px;
        }

        .info-area {
          padding: 0 10px 10px 10px;

          .handle {
            line-height: 24px;
            span {
              font-size: 10px;
            }
          }
        }
      }
    }
  }
}
</style>
