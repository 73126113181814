// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".store-widget-v2__row-column-list-item{box-shadow:0 1px 2px 0 rgba(0,0,0,.05);flex-shrink:0;height:-moz-fit-content;height:fit-content;width:140px}.store-widget-v2__row-column-list-item,.store-widget-v2__row-column-list-item .color-bg{border-radius:var(--goods-border-radius)}.store-widget-v2__row-column-list-item .corner-logo{height:62px;left:-3px;position:absolute;top:-3px;width:62px;z-index:10}.store-widget-v2__row-column-list-item .thumb{border-radius:var(--goods-border-radius) var(--goods-border-radius) 0 0;height:140px;overflow:hidden;width:100%}.store-widget-v2__row-column-list-item .name{color:var(--main-text-color);font-size:12px;font-weight:700;margin-top:8px;padding:0 8px}.store-widget-v2__row-column-list-item .original-price{color:var(--second-text-color);font-size:10px;-webkit-text-decoration:line-through;text-decoration:line-through}.store-widget-v2__row-column-list-item .pre-btn{background-color:rgba(0,0,0,.3);color:#fff}.store-widget-v2__row-column-list-item .pre-btn,.store-widget-v2__row-column-list-item .price-btn{border-radius:var(--goods-border-radius);font-size:12px;line-height:24px;margin:8px;text-align:center}.store-widget-v2__row-column-list-item .price-btn{background-color:var(--button-bg-color);color:var(--button-text-color);padding:0 5px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
