<template>
  <widget-content
    class="activity-widget"
    :class="isMobile ? 'activity_content_mobile relative' : 'activity_content_desk relative'"
    :bg="model.background"
    :mobile-bg="model.mobileBackground"
  >
    <div
      v-if="model.titleVisible"
      :class="['flex items-center relative justify-center', !isMobile ? 'mb-[8px]' : 'mb-[4px]', {'px-[32px]': !isMobile}, {'px-[8px]': isMobile}]"
    >
      <rich-text
        v-model="model.title"
        :editing="editing"
        :stroke.sync="model.titleStroke"
        :disabled="!editing"
        placement="top"
        theme="snow"
        :exclude="['font-size']"
        class="w-full activity_title title"
      />
    </div>
    <div :class="['flex items-center relative justify-center', !isMobile ? 'mb-[30px]' : 'mb-[16px]',{'px-[32px]': !isMobile}, {'px-[8px]': isMobile}]">
      <rich-text
        v-if="model.subtitleVisible"
        v-model="model.subtitle"
        :stroke.sync="model.subTitleStroke"
        :editing="editing"
        :disabled="!editing"
        :exclude="['font-size']"
        placement="top"
        theme="snow"
        class="w-full activity_desc title"
      />
    </div>
    <content-component :model="model" :editing="editing" :is-mobile="isMobile" />
  </widget-content>
</template>

<script>
import ContentComponent from './content.vue'
import WidgetContent from '~/site/components/widgetContent'
import richText from '~/components/richText'

export default {
  name: 'ActivityWidgetEdit',
  components: {
    richText,
    WidgetContent,
    ContentComponent
  },
  props: {
    model: {
      type: Object,
      default() {
        return {}
      },
    },
    editing: {
      type: Boolean,
      default: false,
    },
    device: {
      type: String,
      default: 'desktop',
    },
  },
  data() {
    return {
      noData: false
    }
  },
  computed: {
    isMobile() {
      return this.device === 'mobile'
    }
  }
}
</script>

<style lang="less">
.activity-widget > div:last-child {
  max-width: 1212px;
}
.activity_content_desk {
  padding: 60px 0 40px 0;

  .activity_title {
    font-size: 40px;
    line-height: 70px;
  }

  .activity_desc {
    font-size: 18px;
    line-height: 36px;
  }
}

.activity_content_mobile {
  padding: 40px 0 40px 16px;

  .activity_title {
    font-size: 22px;
    line-height: 36px;
  }

  .activity_desc {
    font-size: 12px;
    line-height: 24px;
  }
}
</style>
