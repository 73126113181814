<template>
  <el-dialog class="convert-key-confirm" :class="{ 'is-mobile': device === DeviceEnum.MOBILE }" :visible.sync="visible" width="720px">
    <div class="confirm-content">
      <div class="title flex justify-center w-full">
        <i class="el-icon-warning text-24 mr-2" />
        <span class="text-18 info-text">{{ $t('siteBuild.convertTip1') }}</span>
      </div>
      <div class="mt-8 confirm-content__info">
        <div class="text-center text-18">
          <span class="font-bold mr-5">{{ $t('siteBuild.header.userName') }}:</span>
          <span>{{ userInfo?.name }}</span>
        </div>
        <div class="text-center mt-2 text-18">
          <span class="font-bold mr-5">{{ $t('siteBuild.header.warZone') }}:</span>
          <span>{{ userInfo?.server }}</span>
        </div>
      </div>
      <div class="gift-area">
        <div class="confirm-content__gift w-max flex justify-center my-6 gap-5">
          <div v-for="(item, index) in detail.gift" :key="index" class="item w-[80px] h-[80px] relative flex items-center flex-col justify-center flex-shrink-0 bg-[#E5AB36]">
            <img class="max-w-[80px] max-h-[60px] object-contain" :src="item.gift_goods_logo" alt="">
            <div class="text-white shadow-box">{{ item.num }}</div>
          </div>
        </div>
      </div>
      <div class="confirm-content__footer text-center mt-8">
        <el-button class="w-auto mx-4" @click="handleCancel">{{ $t('siteBuild.cancel') }}</el-button>
        <site-button class="w-auto min-w-[80px] mx-4" :loading="loading" @click="handleSubmit">{{ $t('siteBuild.convert') }}</site-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {Dialog, Message} from "element-ui"
import {mapState} from "vuex"
import SiteButton from "~/site/components/forms/button.vue"
import {DeviceEnum} from "~/enums/deviceEnum";

export default {
  name: 'ConvertKeyConfirm',
  components: {
    SiteButton,
    [Dialog.name]: Dialog
  },
  props: {
    device: {
      type: String,
      default: ''
    },
    detail: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      visible: false,
      loading: false
    }
  },
  computed: {
    DeviceEnum() {
      return DeviceEnum
    },
    ...mapState({
      userInfo: (state) => state.user.siteUserInfo,
      projectId: (state) => state.project.projectId,
    })
  },
  methods: {
    handleCancel() {
      this.visible = false
    },
    init() {
      this.visible = true
    },
    async handleSubmit() {
      const params = {
        project_id: this.projectId,
        redemption_code: this.detail.redemption_code
      }
      this.loading = true
      const [err] = await this.$utils.to(this.$api.convertKey.convert(params))
      this.loading = false
      if (!err) {
        Message.success('Success')
        this.handleCancel()
      } else {
        Message.error(err)
      }
    }
  }
}
</script>

<style lang="less">
.convert-key-confirm {
  .info-text {
    word-break: break-word;
  }
  .el-dialog__header {
    display: none;
  }
  .confirm-content {
    &__gift {
      overflow: auto;
      margin: 20px auto 0 auto;
      .shadow-box {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        text-align: center;
        font-size: 12px;
      }
    }
    &__footer {
      .el-button {
        border-radius: var(--button-border-radius-px);
      }
    }
  }
  .gift-area {
    margin: 0 auto;
    width: 80%;
    overflow: auto;
  }
  &.is-mobile {
    .el-dialog {
      width: 90% !important;
    }
  }
  .el-icon-warning {
    color: @warning-color;
  }
}
</style>
