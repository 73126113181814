<template>
<div v-imloading="loading" class="invite-widget" :class="{'is-mobile': isMobile}" :style="getStyle">
  <div class="invite-widget__content">
    <rich-text v-model="model.title" :editing="editing" class="w-full title" :class="[isMobile ? 'text-[22px]' : 'text-[40px]']" :disabled="!editing" theme="snow" />
    <rich-text v-model="model.subtitle" :editing="editing" :disabled="!editing" :class="[isMobile ? 'text-[12px]' : 'text-[18px]']" theme="snow" class="mt-4" />
    <bg-style :bg="model.pcBg" :mobile-bg="model.mobileBg" class="invite-widget__content-inner">
      <bind-code v-if="statusType === 'bind' || editing" :model="model" :editing="editing" :confirm-loading="confirmLoading" @create="handleCreateInviter" @bind-success="onBind" />
      <template v-if="statusType === 'inviter' || editing">
        <div class="invite-widget__code mb-4 relative" :class="{ 'mt-10': editing, 'border-t': editing, 'pt-10': editing }">
          <div class="cursor-pointer hover:underline desc-color" @click="handleShowGuide">{{ $t('siteBuild.inviteGuide') }}</div>
          <div class="font-bold text-[30px] gear-color">{{ editing ? 'Invite Code' : inviteCode }}</div>
          <div v-if="inviteCode || editing" class="cursor-pointer hover:underline text-right desc-color" @click="handleShowHistory">{{ $t('siteBuild.inviteHistory') }}</div>
        </div>
        <bg-style v-if="!inviteCode" v-imloading="confirmLoading" :bg="model.buttonBackground" :radius="model.buttonRadius" class="confirm-btn cursor-pointer" @click.native="handleCreateInviter">
          <span class="relative">{{ $t('siteBuild.createInviter') }}</span>
        </bg-style>
        <bg-style v-else v-imloading="confirmLoading" :bg="model.buttonBackground" :radius="model.buttonRadius" class="confirm-btn cursor-pointer" @click.native="handleCopy">
          <span class="relative">{{ $t('siteBuild.copyInviteCode') }}</span>
        </bg-style>
        <div class="invite-list justify-center">
          <div v-for="(item, index) in inviteCountList" :key="index" class="item" :class="{ 'active': currentCountIndex === index }" @click="handleChangeGiftList(index)">
            <i18n path="siteBuild.inviteNumberTip">
              <template slot="count">
                <span class="gear-color font-bold">{{ item.count }}</span>
              </template>
            </i18n>
          </div>
        </div>
        <div class="gift-box mt-5">
          <div class="text-center">{{ $t('siteBuild.inviterTip') }}：</div>
          <div class="overflow-auto flex items-center flex-nowrap justify-center pt-5 gap-3">
            <div v-for="(item, index) in inviteCountList[currentCountIndex]?.gifts" :key="index" class="item">
              <img class="w-full h-full" :src="item.gift_goods_logo" alt="" />
              <div class="number">{{ item.num }}</div>
            </div>
          </div>
        </div>
        <div v-for="(item, index) in paymentCountList" :key="index" class="payment-box mt-5">
          <div class="text-center">PART 0{{ index + 1 }} <span class="font-bold gear-color">{{ $t('siteBuild.payment') }} {{ item.numberPrice }} {{ item.currencyType === 2 ? item.currency : '' }}</span></div>
          <div class="mt-2 desc-color text-center">{{ $t('siteBuild.inviteeTip', { number: `${item.numberPrice} ${item.currencyType === 2 ? item.currency : ''}` }) }}：</div>
          <div class="overflow-auto flex items-center justify-center flex-nowrap pt-5 gap-3">
            <div v-for="(gift, giftIndex) in item.gifts" :key="giftIndex" class="item">
              <img class="w-full h-full" :src="gift.gift_goods_logo" alt="" />
              <div class="number">{{ gift.num }}</div>
            </div>
          </div>
        </div>
        <div v-if="model.mediaVisible && model.mediaList?.length" class="media-box">
          <div v-for="(item, index) in model.mediaList" :key="index" class="item" @click="handleTo(item)">
            <img :src="item.logo" class="w-[60px] h-[60px]" alt="" />
          </div>
        </div>
<!--        <invite-rule v-if="!editing" class="mt-8" :model="model" :editing="editing" />-->
      </template>
    </bg-style>
  </div>
  <guide-dialog ref="$guide" />
  <history-dialog ref="$history" :payment-columns="paymentCountList" />
</div>
</template>

<script>
import {Message} from "element-ui";
import {mapState} from "vuex";
import GuideDialog from "./components/guideDialog.vue";
import HistoryDialog from './components/historyDialog.vue'
import BindCode from "./components/bindCode.vue";
// import InviteRule from './components/rule.vue'
import RichText from "~/components/richText/index.vue";
import {DeviceEnum} from "~/enums/deviceEnum"
import {checkUser, copyText, navTo} from "~/utils";
import Bus from "~/site/model/bus";
import {InviteType} from "~/enums/data";

export default {
  name: 'InviteCodeWidget',
  components: {
    HistoryDialog,
    RichText,
    BindCode,
    // InviteRule,
    GuideDialog
  },
  props: {
    model: {
      type: Object,
      default() {
        return {}
      }
    },
    device: {
      type: String,
      default: 'desktop'
    },
    editing: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isInviter: false,
      isInvitee: false,
      loading: false,
      isNew: false,
      inviteCode: '',
      inviteCountList: [],
      paymentCountList: [],
      currentCountIndex: 0,
      confirmLoading: false
    }
  },
  computed: {
    isMobile() {
      return this.device === DeviceEnum.MOBILE
    },
    ...mapState({
      userInfo: (state) => state.user.siteUserInfo,
      projectId: (state) => state.project.projectId,
      siteId: (state) => state.project.info.id
    }),
    statusType() {
      if ([this.isInviter, this.isInvitee].some(item => item) || !this.isNew) {
        return 'inviter'
      } else if (this.isNew && [this.isInviter, this.isInvitee].every(item => !item)) {
        return 'bind'
      }
      return 'bind'
    },
    getStyle() {
      return {
        '--invite-code-button-color': this.model.buttonColor,
        '--invite-code-button-radius': this.model.buttonRadius + 'px',
        color: this.model.textColor,
        '--invite-code-gear-color': this.model.gearColor,
        '--invite-code-desc-color': this.model.descColor,
        '--invite-code-text-color': this.model.textColor
      }
    }
  },
  beforeDestroy() {
    Bus.$off('reloadActivity')
  },
  created() {
    this.getDetail()
    Bus.$on('reloadActivity', async () => {
      await this.getDetail()
    })
  },
  methods: {
    handleTo(item) {
      navTo(this, item)
    },
    async handleCopy() {
      await copyText(this.inviteCode)
      Message.success('Success')
    },
    async getUserInfoByInviteCode() {
      if (!this.userInfo?.uid) return
      const [err, res] = await this.$utils.to(this.$api.activity.getInviteCodeUserInfo())
      if (!err) {
        this.inviteCode = res.invite_code
        this.isInviter = res.is_inviter
        this.isInvitee = res.is_invitee
        this.isNew = res.is_new
      } else {
        Message.error(err)
      }
    },
    handleShowGuide() {
      if (this.editing) return
      this.$refs.$guide.init()
    },
    // async getInviteCodeDetail() {
    //   if (isNaN(this.userInfo?.id)) return
    //   const params = {}
    //   const [err, res] = await this.$utils.to(this.$api.activity.inviteCodeDetail(params))
    //   if (!err) {
    //     console.log(res)
    //   } else {
    //     Message.error(err)
    //   }
    // },
    async getDetail() {
      this.loading = true
      const params = {
        project_id: this.projectId
      }
      const [err, res] = await this.$utils.to(this.$api.activity.getInviteCodeDetail(params))
      this.loading = false
      if (!err) {
        const { rule } = res
        const count = rule.inviter.gift_type_packages.find(item => item.gift_type === InviteType.count)
        const payment = rule.inviter.gift_type_packages.find(item => item.gift_type === InviteType.payment)
        if (count) {
          this.inviteCountList = count.gift_level_packages?.map(item => {
            return {
              count: item.gift_level_value,
              gifts: item.gifts
            }
          })
        }
        if (payment) {
          this.paymentCountList = payment.gift_level_packages?.map(item => {
            return {
              numberPrice: item.gift_level_value,
              currency: payment.country_currency,
              currencyType: payment.currency_type,
              vCurrency: payment.virtual_currency_id,
              gifts: item.gifts,
              level: item.gift_level
            }
          })
        }
        await this.getUserInfoByInviteCode()
      } else {
        Message.error(err)
      }
    },
    onBind() {
      this.isInvitee = true
    },
    async handleCreateInviter() {
      await checkUser(this)
      this.confirmLoading = true
      const [err, res] = await this.$utils.to(this.$api.activity.inviterCreate())
      this.confirmLoading = false
      if (!err) {
        this.inviteCode = res.invite_code
        this.isInviter = true
      } else {
        Message.error(err)
      }
    },
    handleChangeGiftList(index) {
      this.currentCountIndex = index
    },
    handleShowHistory() {
      if (this.editing) return
      this.$refs.$history.init()
    }
  }
}
</script>

<style lang="less">
.invite-widget {
  padding: 60px 0 40px 0;
  .gear-color {
    color: var(--invite-code-gear-color);
  }
  .desc-color {
    color: var(--invite-code-desc-color);
  }
  .text-color {
    color: var(--invite-code-text-color);
  }
  &__content {
    max-width: 860px;
    margin: 0 auto;
    position: relative;
    &-inner {
      padding: 40px 40px 10px 40px;
      .confirm-btn {
        font-size: 24px;
        margin: 0 auto;
        line-height: 64px;
        width: fit-content;
        text-align: center;
        font-weight: bold;
        padding: 0 20px;
        span {
          color: var(--invite-code-button-color);
        }
      }
      .invite-list {
        display: flex;
        margin-top: 40px;
        padding-bottom: 15px;
        overflow: auto;
        position: relative;
        .item {
          font-size: 18px;
          margin-right: 20px;
          flex-shrink: 0;
          cursor: pointer;
          border-bottom: 2px solid transparent;
          padding-bottom: 15px;
          &.active {
            border-color: currentColor;
          }
        }
      }
      .gift-box {
        border-bottom: 1px solid #dedede;
        padding-bottom: 30px;
      }
      .gift-box,.payment-box {
        position: relative;
        font-size: 18px;
        .item {
          width: 60px;
          height: 60px;
          border: 1px solid #dedede;
          flex-shrink: 0;
          position: relative;
          .number {
            position: absolute;
            width: 100%;
            left: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.6);
            z-index: 10;
            color: white;
            text-align: center;
            font-size: 10px;
          }
        }
      }
      .media-box {
        display: flex;
        overflow: auto;
        margin-top: 40px;
        border-top: 1px solid #dedede;
        padding-top: 20px;
        position: relative;
        justify-content: center;
        gap: 20px;
        .item {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          cursor: pointer;
        }
      }
      .rule-box {
        border-top: 1px solid #dedede;
        padding-top: 40px;
        margin-top: 30px;
        position: relative;
      }
      .invite-code__rule {
        font-size: 18px;
      }
      .invite-code__bind {
        .title {
          font-size: 26px;
        }
        .subtitle {
          font-size: 20px;
        }
      }
      .invite-code__rule {
        .content {
          margin-top: 20px;
        }
      }
    }
  }
  &__code {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;

  }
  &.is-mobile {
    padding: 40px 15px 40px 15px;
    .invite-widget__code {
      font-size: 14px;
    }
    .invite-widget__content-inner {
      padding: 15px 15px 10px 15px;
      .confirm-btn {
        line-height: 40px;
        font-size: 16px;
      }
      .invite-list {
        .item {
          font-size: 14px;
        }
      }
      .gift-box,.payment-box {
        font-size: 14px;
      }
    }
    .invite-code__bind {
      .title {
        font-size: 18px;
      }
      .subtitle {
        font-size: 14px;
      }
      .input-box {
        .inner-input {
          width: 100%;
          height: 40px;
          line-height: 40px;
          font-size: 14px;
        }
        .btn {
          flex-shrink: 0;
          font-size: 14px;
          width: 120px;
          min-width: unset;
          height: 40px;
          line-height: 40px;
        }
      }
    }
    .invite-code__rule {
      font-size: 14px;
      .title {
        font-size: 16px;
      }
      .content {
        margin-top: 10px;
      }
    }
  }
}
</style>
