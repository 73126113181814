<template>
  <component :is="$store.getters.isDesktop ? 'el-dialog' : 'el-drawer'" class="store-widget-v2__detail" :class="{ 'is-mobile': $store.getters.isMobile }" :visible.sync="visible" v-bind="options">
    <div class="content-area">
      <div class="close" @click="close">
        <i class="el-icon-close" />
      </div>
      <div class="scroll-area">
        <div class="goods-info">
          <div v-if="$store.getters.isMobile" class="left-thumb">
            <img :src="good.icon" alt="" />
          </div>
          <bg-style v-else class="left-thumb relative" :bg="{...good.thumbBg, backgroundSize: model.iconFillType}" />
          <div v-if="stopCountdownTimer && $store.getters.isMobile" class="countdown flex items-center mt-4 relative">
            <i class="el-icon-time mr-1" />
            <span>商品下架倒计时：</span>
            <span>{{ stopCountdownDays }}d:{{ stopCountdownHours }}:{{ stopCountdownMinutes }}:{{ stopCountdownSeconds }}</span>
          </div>
          <div class="right-block">
            <div class="name">
              {{ good?.names?.name }}
            </div>
            <div v-if="good?.names?.desc" class="desc mt-2">
              {{ good.names.desc }}
            </div>
            <div class="mt-2 flex items-center">
              <span class="price">{{ realPriceText }}</span>
              <span v-if="isShowOriginalPrice" class="original-price ml-2">{{ originalPriceText }}</span>
              <span v-if="good.is_purchase_limit" class="limit">Limit {{ good.has_purchase_num }}/{{ good.limit_purchase_num }}</span>
            </div>
          </div>
        </div>
        <div v-if="hasContents" class="package-list">
          <div class="subtitle">{{ $t('siteBuild.includes') }}</div>
          <div class="flex items-center mt-2 overflow-auto hide-scroll">
            <div v-for="(item, index) in good.item_contents" :key="index" class="item mr-3">
              <img alt="" :src="item.icon" />
              <div class="text-[10.5px] name mt-1 text-center">
                {{ item.name }}*{{ item.num }}
              </div>
            </div>
          </div>
        </div>
        <div v-if="hasGift" class="gift-list">
          <div class="subtitle">{{ $t('siteBuild.gift') }}</div>
          <div class="flex items-center mt-2 overflow-auto hide-scroll">
            <div v-for="(item, index) in good.gift_list" :key="index" class="item mr-3">
              <img alt="" :src="item.gift_goods_logo" />
              <div class="text-[10.5px] name mt-1 text-center">
                *{{ item.num }}
              </div>
            </div>
          </div>
        </div>
        <div v-if="stopCountdownTimer && $store.getters.isDesktop" class="countdown flex items-center mt-10 relative">
          <i class="el-icon-time mr-1" />
          <span>商品下架倒计时：</span>
          <span>{{ stopCountdownDays }}d:{{ stopCountdownHours }}:{{ stopCountdownMinutes }}:{{ stopCountdownSeconds }}</span>
        </div>
      </div>
      <div v-if="isShowCountdown" class="pre-btn">
        <div class="flex items-center justify-center content-box">
          <i class="el-icon-time text-14 mr-2" />
          <span>{{ countdownDays }}d:{{ countdownHours }}h:{{ countdownMinutes }}m:{{ countdownSeconds }}s</span>
        </div>
      </div>
      <div v-else class="pay-btn">
        <shop-cart v-if="hasShopCart" class="shop-cart" :good="good" :disabled="editing" />
        <div class="price-block" :class="{ 'no-shop-cart': !hasShopCart }" @click="handlePay">{{ realPriceText }}</div>
      </div>
    </div>
  </component>
</template>

<script>
import { Dialog, Drawer, Image } from "element-ui";
import cloneDeep from "lodash.clonedeep";
import ShopCart from './shopCart.vue'
import goodMixins from "~/site/widgets/storeWidget/oneGood/goodMixins";
export default {
  name: 'StoreWidgetV2DetailModal',
  components: {
    [Dialog.name]: Dialog,
    [Drawer.name]: Drawer,
    [Image.name]: Image,
    ShopCart
  },
  mixins: [goodMixins],
  data() {
    return {
      visible: false
    }
  },
  computed: {
    options() {
      if (this.$store.getters.isDesktop) {
        return {
          width: '800px',
          appendToBody: false,
          destroyOnClose: false
        }
      } else {
        return  {
          direction: 'btt',
          size: 'auto',
          showClose: false,
          appendToBody: false,
          withHeader: false,
          destroyOnClose: false
        }
      }
    }
  },
  methods: {
    async init() {
      this.visible = true
      await this.$nextTick()
      this.initGoods()
    },
    close() {
      this.visible = false
    },
    handlePay() {
      this.$emit('submit', cloneDeep(this.good))
    }
  }
}
</script>

<style lang="less">
.store-widget-v2__detail {
  .hide-scroll {
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .close {
    width: 24px;
    height: 24px;
    background-color: var(--button-bg-color);
    color: var(--button-text-color);
    position: absolute;
    right: 16px;
    top: 16px;
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    cursor: pointer;
  }
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    padding: 24px;
    position: relative;
    background-color: var(--goods-bg-color);
    .goods-info {
      display: flex;
      padding-bottom: 20px;
      border-bottom: 1px solid #dedede;
      .left-thumb {
        width: 294px;
        height: 192px;
        margin-right: 20px;
        flex-shrink: 0;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .right-block {
        .name {
          color: var(--main-text-color);
          font-size: 24px;
          font-weight: bold;
        }
        .desc {
          word-break: break-word;
        }
        .price {
          font-size: 24px;
          color: var(--main-text-color);
          font-weight: bold;
        }
        .original-price {
          font-size: 16px;
          text-decoration: line-through;
          margin-left: 12px;
          color: var(--second-text-color);
        }
        .limit {
          background-color: var(--button-bg-color);
          margin-left: 16px;
          font-size: 14px;
          display: inline-block;
          color: var(--button-text-color);
          padding: 1px 4px;
        }
      }
    }
    .subtitle {
      font-size: 16px;
      font-weight: bold;
      color: var(--main-text-color);
    }
    .package-list,.gift-list {
      margin-top: 24px;
      .item {
        flex-shrink: 0;
        img {
          width: 56px;
          height: 56px;
          object-fit: cover;
        }
        .name {
          color: var(--second-text-color);
        }
      }
    }
    .countdown {
      font-size: 16px;
      color: var(--second-text-color);
    }
    .pre-btn {
      display: flex;
      justify-content: center;
      .content-box {
        height: 44px;
        background-color: rgba(0, 0, 0, 0.3);
        width: 260px;
        border-radius: var(--goods-border-radius);
        color: #fff;
        font-size: 14px;
      }
    }
    .pay-btn {
      margin: 30px auto 0 auto;
      display: flex;
      justify-content: center;
      .shop-cart {
        width: 44px;
        height: 44px;
        background-color: var(--button-bg-color);
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--button-text-color);
        font-size: 20px;
        border-radius: var(--goods-border-radius) 0 0 var(--goods-border-radius);
        flex-shrink: 0;
        margin-right: 5px;
      }
      .price-block {
        width: 228px;
        line-height: 44px;
        text-align: center;
        background-color: var(--button-bg-color);
        color: var(--button-text-color);
        border-radius: 0 var(--goods-border-radius) var(--goods-border-radius) 0;
        cursor: pointer;
        &.no-shop-cart {
          border-radius: var(--goods-border-radius);
        }
      }
    }
  }
  .el-drawer__body {
    background-color: var(--goods-bg-color);
    .scroll-area {
      max-height: 80vh;
      overflow: auto;
      padding-bottom: 40px;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .goods-info {
      .left-thumb {
        img {
          width: 100%;
        }
      }
      .right-block {
        padding: 0 14px;
        .name {
          font-size: 18px;
          font-weight: bold;
          color: var(--main-text-color);
          margin-top: 14px;
        }
        .desc {
          font-size: 12px;
          color: var(--second-text-color);
          margin-top: 8px;
        }
        .price {
          font-size: 18px;
          color: var(--main-text-color);
          font-weight: bold;
        }
        .original-price {
          color: var(--second-text-color);
          text-decoration: line-through;
          font-size: 12px;
        }
        .limit {
          background-color: var(--button-bg-color);
          margin-left: 14px;
          font-size: 10px;
          display: inline-block;
          color: var(--button-text-color);
          padding: 1px 4px;
        }
      }
      .countdown {
        margin-left: 14px;
        width: fit-content;
        background-color: var(--button-bg-color);
        color: var(--button-text-color);
        font-size: 12px;
        padding: 5px 10px;
        border-radius: 14px;
      }
    }
    .subtitle {
      font-size: 16px;
      color: var(--main-text-color);
      font-weight: bold;
    }
    .pre-btn {
      padding: 12px 14px;
      box-shadow: 0px -2px 6px 0px rgba(0, 0, 0, 0.05);
      .content-box {
        height: 44px;
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: var(--goods-border-radius);
        color: #fff;
        font-size: 14px;
      }
    }
    .pay-btn {
      padding: 12px 14px;
      display: flex;
      box-shadow: 0px -2px 6px 0px rgba(0, 0, 0, 0.05);
      .shop-cart {
        width: 42px;
        height: 42px;
        background-color: var(--button-bg-color);
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--button-text-color);
        font-size: 20px;
        border-radius: var(--goods-border-radius) 0 0 var(--goods-border-radius);
        flex-shrink: 0;
      }
      .price-block {
        line-height: 42px;
        text-align: center;
        background-color: var(--button-bg-color);
        color: var(--button-text-color);
        width: 100%;
        border-radius: 0 var(--goods-border-radius) var(--goods-border-radius) 0;
        margin-left: 10px;
        &.no-shop-cart {
          margin-left: 0;
          border-radius: var(--goods-border-radius);
        }
      }
    }
    .package-list,.gift-list {
      margin-top: 38px;
      padding-left: 14px;
      .item {
        flex-shrink: 0;
        img {
          width: 56px;
          height: 56px;
          object-fit: cover;
        }
        .name {
          color: var(--second-text-color);
        }
      }
    }
  }
}
</style>
