<template>
  <div>
    <MenuTitle :close="close">{{$t('model.footerQuickLink')}}</MenuTitle>
    <div v-for="(item, index) in target.list" :key="index" class="bg-fillColor-2 p-2 mt-4">
      <div class="flex justify-between items-center text-12">
        <span>链接分组 {{ index + 1 }}</span>
        <i class="el-icon-close hover:text-danger text-14 cursor-pointer" @click="handleRemoveItem(index)" />
      </div>
      <menu-item class="pt-2" :label="$t('title')">
        <base-input v-model="item.title" placeholder="链接标题" />
      </menu-item>
      <menu-item class="pt-2" label="菜单">
        <nav-add :model="item" :popover-enabled="true" :site="site" :max-length="10" :index="index" target-key="children" :target-index="childrenIndex" />
      </menu-item>
    </div>
    <menu-button class="mt-4" icon="add" @click.native="handleAddLinkGroup">添加链接</menu-button>
    <delete-block-dialog-trigger class="mt-4" @delete-block="deleteBlock" />
  </div>
</template>

<script>
import cloneDeep from "lodash.clonedeep";
import {FooterQuickLinkWidgetModel} from '../../model'
import FooterMixin from './mixinEdit'
import menuTitle from "~/components/menu/menuTitle.vue"
import NavAdd from "~/components/menu/navAdd";
import MenuButton from "~/components/menu/menuButton.vue";
import EditPopover from "~/site/components/editMenu/editPopover.vue";

export default {
  name: "FooterQuickLinkWidgetEdit",
  _key: 'navediter',
  components: {
    EditPopover,
    MenuButton,
    NavAdd,
    menuTitle
  },
  mixins: [FooterMixin],
  props: {
    site: {
      type: Object,
      default() {
        return {}
      }
    },
    model: {
      type: Object,
      default() {
        return {}
      }
    },
    target: {
      type: Object,
      default() {
        return new FooterQuickLinkWidgetModel()
      }
    }
  },
  data() {
    return {
      currentNav: {}
    }
  },
  computed: {
    childrenIndex() {
      return this.model.children.findIndex(item => item.id === this.target.id)
    }
  },
  created() {
    //
  },
  methods: {
    handleAddLinkGroup() {
      const that = this
      this.target.list.push(cloneDeep(that.target.__navItem))
    },
    handleRemoveItem(index) {
      this.target.list.splice(index, 1)
    }
  }
}
</script>

<style lang="less" scoped>
.nav-add-wrap {
  .navs {
    .nav-item {
      .label {
        font-size: 16px;
        color: #FFFFFF;
        font-weight: 400;
        margin-bottom: 8px;
        padding-left: 14px;
        position: relative;
      }

      .label.icon::before {
        display: block;
        content: '';
        position: absolute;
        top: 8px;
        left: 0;
        width: 6px;
        height: 6px;
        background: @primary-color;
        border-radius: 100%;
      }

      .right {
        svg:first-child {
          margin-right: 16px;
        }
      }

    }
  }

  .add-nav {
    width: 100%;
    // padding: 0px 22px;
    background: #151724;
    // margin-top: 18px;
    .btn {
      height: 40px;
      line-height: 40px;
      padding: 0 16px;
      cursor: pointer;
      border: 0.5px dashed rgba(255, 255, 255, 0.6);
      border-radius: 2px;

      span {
        margin-left: 8px;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.6);
      }
    }
  }
}

</style>
