<template>
  <bg-style :bg="model.background" :mobile-bg="model.mobileBackground" class="convert-key-widget px-5" :class="{ 'is-mobile': $store.getters.isMobile }" :style="getStyle">
    <div class="convert-key-widget__content relative">
      <rich-text v-if="model.titleVisible" v-model="model.title" :stroke.sync="model.titleStroke" :exclude="['font-size']" :editing="editing" :class="[$store.getters.isMobile ? 'text-[22px]' : 'text-[40px]']" class="w-full title" theme="snow" :disabled="!editing" />
      <rich-text v-if="model.subtitleVisible" v-model="model.subtitle" :stroke.sync="model.subTitleStroke" :exclude="['font-size']" :editing="editing" :class="[$store.getters.isMobile ? 'text-[12px]' : 'text-[18px]']" class="mt-3" :disabled="!editing" theme="snow" />
      <im-form class="mt-10" label-position="top">
        <div class="w-[550px] m-0-auto" :class="{ '!w-full': $store.getters.isMobile }">
          <im-input v-model.trim="code" class="convert-input" :class="{ 'is-edit': editing }" :placeholder="$t('siteBuild.convertInput')" :disabled="editing" />
        </div>
        <form-item class="mt-[64px] mb-0">
          <div class="flex w-full items-center justify-center">
            <im-button class="submit-btn" :disabled="submitDisabled" :loading="loading" @click="handleSubmit">{{ $t('siteBuild.convert') }}</im-button>
          </div>
        </form-item>
      </im-form>
    </div>
    <confirm-dialog ref="confirm" :device="device" :detail="detail" />
  </bg-style>
</template>

<script>
import {mapState} from 'vuex'
import {Message} from "element-ui"
import isNil from "lodash.isnil"
import tinycolor from "tinycolor2"
import ConfirmDialog from './confirm.vue'
import RichText from "~/components/richText/index.vue"
import { Form, FormItem, Input, Button } from '~/site/components/forms'
import {DeviceEnum} from "~/enums/deviceEnum"
import {getTextColor} from "~/utils"
export default {
  name: 'ConvertKeyWidget',
  components: {
    ConfirmDialog,
    RichText,
    ImForm: Form,
    FormItem,
    ImInput: Input,
    ImButton: Button
  },
  props: {
    editing: {
      type: Boolean,
      default: false
    },
    device: {
      type: String,
      default: DeviceEnum.MOBILE
    },
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      loading: false,
      code: '',
      detail: {
        redemption_code: '',
        gift: []
      }
    }
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.siteUserInfo,
      projectId: (state) => state.project.projectId,
    }),
    getStyle() {
      return {
        '--convert-key-radius': this.model.radius + 'px',
        '--convert-key-backgroundColor': getTextColor(this.model.inputBackground).color,
        '--convert-key-borderColor': getTextColor(this.model.borderBackground).color,
        '--convert-key-button-backgroundColor': getTextColor(this.model.buttonBackground).color,
        '--convert-key-button-color': getTextColor(this.model.buttonTextColor).color,
        '--convert-key-input-color': getTextColor(this.model.inputTextColor).color,
        '--convert-key-shadow-color': tinycolor(this.model.buttonTextColor.backgroundColor).setAlpha(0.3).toRgbString()
      }
    },
    inputStyle() {
      return this.editing ? {
        border: `2px solid ${this.getStyle["--convert-key-borderColor"]}`
      } : null
    },
    submitDisabled() {
      return !this.code && !this.editing
    }
  },
  methods: {
    checkRight() {
      let result = true
      if (isNil(this.userInfo?.id)) {
        this.$store.commit('user/SET_LOGINSHOW', !this.editing)
        this.$store.commit('user/SET_SITE_BIND_USER', true)
        result = false
      } else if (!this.code) {
        Message.error('Please Input Code')
        result = false
      }
      return result
    },
    async handleSubmit() {
      const valid = this.checkRight()
      if (!valid) return
      const params = {
        project_id: this.projectId,
        redemption_code: this.code
      }
      this.loading = true
      const [err, res] = await this.$utils.to(this.$api.convertKey.detail(params))
      this.loading = false
      if (!err) {
        this.detail = res
        this.$refs.confirm.init()
      } else {
        Message.error(err)
      }
    }
  }
}
</script>

<style lang="less">
.convert-key-widget {
  padding: 60px 0 40px 0;
  .m-0-auto {
    margin: 0 auto;
  }
  &__content {
    max-width: 1240px;
    margin: 0 auto;
    .convert-input {
      .site-input__inner {
        font-family: PingFangSC-Medium;
        height: 61px;
        text-align: center;
        background-color: var(--convert-key-backgroundColor);
        font-size: 20px;
        border-radius: var(--convert-key-radius);
        z-index: 10;
        position: relative;
        border: 2px solid transparent;
        color: var(--convert-key-input-color);
        &::placeholder {
          color: var(--convert-key-placeholder-color);
        }
        &:focus {
          border-color: var(--convert-key-borderColor);
        }
      }
      &.is-edit {
        .site-input__inner {
          border: 2px solid var(--convert-key-borderColor) !important;
        }
      }
    }
    .submit-btn {
      font-size: 20px;
      height: 46px;
      line-height: 46px;
      width: 142px;
      background-color: var(--convert-key-button-backgroundColor);
      color: var(--convert-key-button-color);
      box-shadow: inset 0 3px 0 var(--convert-key-shadow-color);
      &:disabled {
        background-color: var(--convert-key-button-backgroundColor);
        color: var(--convert-key-button-color);
      }
    }
  }
  &__gift {
    display: flex;
    width: 100%;
    align-items: center;
    overflow: auto;
    gap: 10px;
    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      flex-shrink: 0;
    }
  }
  &.is-mobile {
    padding: 40px 0 40px 0;
    .site-input__inner {
      &:placeholder-shown {
        font-size: 18px;
      }
    }
  }
}
</style>
