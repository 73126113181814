<template>
  <bg-style class="widget-content" :style="{overflow: isShowBackgroundImageBlur? 'hidden': ''}" :bg="bg" :mobile-bg="mobileBg" :custom-style="customStyle">
    <div>
      <slot></slot>
    </div>
  </bg-style>
</template>

<script>
import {computeBackgroundStyle, isShowColor, isShowBackgroundImage, isShowBackgroundImageBlur} from '~/site/model/helper'

export default {
  name: 'WidgetContentMax',
  props: {
    bg: {
      type: Object,
      default() {
        return {}
      }
    },
    mobileBg: {
      type: Object,
      default() {
        return {}
      }
    },
    customStyle: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  computed: {
    isShowBackgroundImageBlur() {
      return isShowBackgroundImageBlur(this.bg)
    },
    isShowColor() {
      return isShowColor(this.bg)
    },
    isShowBackgroundImage() {
      return isShowBackgroundImage(this.bg)
    },
    bgStyle() {
      return computeBackgroundStyle(this.bg, this.customStyle)
    }
  }
}
</script>

<style lang="less" >
.widget-content{
  > div:last-child{
    max-width: 1280px;
    margin: 0 auto;
  }
}
</style>
