<template>
  <bg-style class="store-widget-v2__two-column-list grid grid-cols-2 gap-4">
    <two-column-item v-for="(item, index) in list" :key="index" :site="site" :editing="editing" :good="item" :model="model" @click="handleShowDetail" @submit="onSubmit" />
  </bg-style>
</template>


<script>
import TwoColumnItem from './item.vue'
export default {
  name: 'StreetWidgetV2TwoColumnList',
  components: {
    TwoColumnItem
  },
  props: {
    editing: {
      type: Boolean,
      default: false
    },
    site: {
      type: Object,
      default() {
        return {}
      }
    },
    model: {
      type: Object,
      default() {
        return {}
      }
    },
    list: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {}
  },
  methods: {
    handleShowDetail(goods) {
      this.$emit('show-detail', goods)
    },
    onSubmit(goods) {
      this.$emit('submit', goods)
    }
  }
}
</script>

<style lang="less">
.store-widget-v2__two-column-list {
  padding: 14px 14px 0 14px;
}
</style>
